import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corousel',
  templateUrl: './corousel.component.html',
  styleUrl: './corousel.component.css'
})
export class CorouselComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef){}

  public images: string[] = [
    './../../../../assets/images/banner-home/Imagen1.webp',
    './../../../../assets/images/banner-home/Imagen2.webp',
    './../../../../assets/images/banner-home/Imagen3.webp',
    './../../../../assets/images/banner-home/Imagen4.webp',
    './../../../../assets/images/banner-home/Imagen5.webp',
    // Agrega más URLs de imágenes según sea necesario
  ];

  public currentImageIndex: number = 0;

  ngOnInit(): void {
    this.startCarousel();
  }

  startCarousel() {
    setInterval(() => {
      this.showNextImage();
    }, 5000); // Cambia de imagen cada 5 segundos (5000 milisegundos)
  }
  
  showNextImage() {
    this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
    this.cdr.detectChanges(); // Detectar cambios manualmente después de cambiar la imagen
  }

}
