import {NgModule} from '@angular/core';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {RouterModule, RouterOutlet} from "@angular/router";
import {GalleryModule} from "ng-gallery";
import {LightboxModule} from "ng-gallery/lightbox";
import {TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {NavLayoutComponent} from './components/layouts/nav-layout/nav-layout.component';
import {AuthLayoutComponent} from './components/layouts/auth-layout/auth-layout.component';
import {MaterialModule} from "./modules/material.module";
import {LnComponentsModule} from "./components/ln-customs-material-components/ln-components.module";
import {HeaderComponent} from "./components/header/header.component";
import {FooterComponent} from './components/footer/footer.component';
import {LnAlertComponent} from './components/alerts/ln-alert/ln-alert.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthStateDirectiveDirective} from './directives/auth-state-directive.directive';
import {AuthStateAnonymousDirective} from "./directives/auth-state-anonymous-directive";
import {SearchBarMobileComponent} from './components/search-bar-mobile/search-bar-mobile.component';
import {LnGoogleMapModule} from "./ln-google-map/ln-google-map.module";
import {SearchHeaderMobileComponent} from './components/search-header-mobile/search-header-mobile.component';
import {SortPostsComponent} from "./components/sort-posts/sort-posts.component";
import {ImagesGalleryComponent} from './components/images-gallery/images-gallery.component';
import {LnIframeComponent} from './components/ln-iframe/ln-iframe.component';
import {LnDialogIframeComponent} from "./components/ln-dialog-iframe/ln-dialog-iframe.component";
import { ServicesFormComponent } from './components/services-form/services-form.component';
import {ContactFormRepository} from "../core/repositories/contact-form.repository";
import {ContactFormWebRepository} from "../data/repository/contact-form.web-repository";
import {ContactFormUseCaseService} from "../core/usecase/contact-form-use-case.service";
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { LnDialogLoginRegistSessionComponent } from './components/ln-dialog-login-regist-session/ln-dialog-login-regist-session.component';
import {LnDialogComponent} from "./components/ln-dialog/ln-dialog.component";
import { CardsComponent } from './components/cards/cards.component';
import { ServicePetsComponent } from './components/service-pets/service-pets.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { CorouselComponent } from './components/corousel/corousel.component';
import { ClearButtonComponent } from "./components/clear-button/clear-button.component";
import { FiltersService } from '../core/services/filters.service';
import { MapService } from '../core/services/map-trigger.service';
import { DataFetchingService } from '../core/services/data-fetching.service';
import { PriceService } from './services/price.service';
import { IsPetService } from './services/isPet.service';
import { StoreFiltersService } from './services/storeFilters.service';
import { BookingCardComponent } from './components/booking-card/booking-card.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ShortBookingCardComponent } from './components/short-booking-card/short-booking-card.component';
import { ReservartionCardComponent } from './components/reservartion-card/reservartion-card.component';
import { AlojamientosRepository } from '../core/repositories/alojamientos-post.repository';
import { AlojamientosPostWebRespository } from '../data/repository/alojamientos-post.web-repository';
import { ToastComponent } from './components/toast/toast.component';
import { BbProfileContactComponent } from './components/bb-profile-contact/bb-profile-contact.component';
import { CardVisitedReservationComponent } from './components/card-visited-reservation/card-visited-reservation.component';
import { FailedReservationComponent } from './components/failed-reservation/failed-reservation.component';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { CustomDecimalSeparatorPipe } from './pipes/custom-km.pipe';


const components = [
    AuthLayoutComponent,
    NavLayoutComponent,
    LnAlertComponent,
    HeaderComponent,
    FooterComponent,
    SearchBarComponent,
    SearchBarMobileComponent,
    AuthStateDirectiveDirective,
    AuthStateAnonymousDirective,
    SearchHeaderMobileComponent,
    SortPostsComponent,
    ImagesGalleryComponent,
    LnIframeComponent,
    LnDialogIframeComponent,
    ServicesFormComponent,
    ContactFormComponent,    
    LnDialogLoginRegistSessionComponent,
    LnDialogComponent,
    CardsComponent,
    ServicePetsComponent,
    CorouselComponent,
    ClearButtonComponent,
    BookingCardComponent,
    ShortBookingCardComponent,
    ReservartionCardComponent,
    PaginatorComponent,
    ToastComponent,
    BbProfileContactComponent,
    BookingCardComponent, 
    PaginatorComponent,
    BbProfileContactComponent,
    CardVisitedReservationComponent,
    FailedReservationComponent,
    CustomCurrencyPipe,
    CustomDecimalSeparatorPipe
]

const modules = [
    LnComponentsModule,
    ClipboardModule,
    MaterialModule,
    TranslateModule,
    LnGoogleMapModule
]

@NgModule({
    declarations: [...components],
    exports: [...components, ...modules],
    providers: [
        CurrencyPipe,
        { provide: ContactFormRepository, useClass: ContactFormWebRepository },
        {provide: AlojamientosRepository, useClass: AlojamientosPostWebRespository},
        ContactFormUseCaseService,
        TranslatePipe,
        FiltersService,
        MapService,
        DataFetchingService,
        PriceService,
        IsPetService,
        StoreFiltersService,
    ],
    imports: [
        CommonModule,
        RouterOutlet,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        GalleryModule,
        LightboxModule,
        ...modules,
    ]
})
export class SharedModule {
}
