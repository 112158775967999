import { Injectable } from '@angular/core';
import { getAuth, onAuthStateChanged } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, } from '@angular/router';


@Injectable({
	providedIn: 'root',
})
export class UserIsLogedIn implements CanActivate {

	constructor(private router: Router) {
	}

	canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return new Promise((resolve, reject) => {
			onAuthStateChanged(getAuth(), (user) => {
				if (user && user.emailVerified) {
					resolve(true);
				} else {
					reject(this.router.navigate(['/sessions/signin'], { queryParams: { returnUrl: state.url } }));
				}
			});
		});
	}
}


@Injectable({
	providedIn: 'root',
})
export class UserNotLogedIn implements CanActivate {
	constructor(private router: Router) { }

	canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return new Promise((resolve) => {
			onAuthStateChanged(getAuth(), (user) => {
				if (user && !user.isAnonymous && user.emailVerified) {
					const returnUrl = state.url.includes('returnUrl') ? state.url.split('returnUrl=')[1] : '/';
					this.router.navigateByUrl(returnUrl);
					resolve(false);
				} else {
					resolve(true);
				}
			});
		});
	}
}

