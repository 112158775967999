<div class="bookingCardContainer">

    <app-ln-date-picker [cardBooking]="'datePicker'" (dateRangeSelected)="onDateRangeChanged($event)" [fechasBloqueo]="fechasBloqueo"></app-ln-date-picker>
    <app-ln-guest-selector [adults]="Adults" [pets]="Pets" [infants]="Infants" [children]="Children"
    (adultsChange)="handleAdultsChange($event)" (petsChange)="handlePetsChange($event)"
    (infantsChange)="handleInfantsChange($event)" (childrenChange)="handleChildrenChange($event)"
    [maxGuests]="maxGuests" [customClass]="'cardCustom'" [mascotas]="mascotas"></app-ln-guest-selector>
    <div class="checks">
        <div>
            <h3>Check In</h3>
            <p>{{checkin}} PM</p>
        </div>
        <div>
            <h3>Check Out</h3>
            <p>{{checkout}} AM</p>
        </div>
    </div>


</div>