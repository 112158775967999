export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: "AIzaSyBeJBAckugwdEH8HzWrP7qv957f0bAscVg",
        authDomain: "sza-preprod.firebaseapp.com",
        projectId: "sza-preprod",
        storageBucket: "https://dev.staticstorage.lunanuevaportal.com",
        messagingSenderId: "359616739836",
        appId: "1:359616739836:web:b86da7f5f466c7fc221a79",
        measurementId: "G-6GDN0854BK"
    },
    tenantId: "dev-ln-property-user-c5xsh",
    baseURL: "https://dev.api.properties.lunanuevaportal.com/api/v1/",
    endpoints: {
        session: 'session',
        register: 'register',
        cities: "cities",
        propertyTypes: "tipoPropiedad",
        properties: "properties",
        posts: "alojamientos/",
        aviablePosts: "alojamientos/disponibles",
        latlangPost: "alojamientos/coordenadas",
        emailServices: "email/services",
        emailContact: "email/contact",
        postAsFavorite: "mark-favorite",
        realState: "real-state",
        userData: "user-data",
        userPosts: "posts-user",
        publisher:"propiedades/bedBreakfastMenu",
        publisher_list:"propiedades/bedBreakfast",
        prices: "alojamientos/prices",
        bedBreakfast:"propiedades/bedBreakfast",
        bedBreakfastProfile:"propiedades/bedBreakfastProfile",
        favoritePost: "user-data/like",
        myFavorites:"alojamientos/likes",
        reservation: "reservas",
        reservationPriceChanged:"tarifas",
        userReservations:"reservas/usuario",
        user_active_reservations:"reservas/sinPaginar"

    },
    firebaseVerify: "https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo?key=AIzaSyDGEN8PeSAfqRlwA-Z9wUwnalSm7To0_l4",
    googleMapApiKey: "AIzaSyCJx9U6UPxx8rNXXhVCdS3WK9RBvFf4HGc",
    recaptcha: {
        publicKey: "0x4AAAAAAACrvYbrkxvyQt_k"
    },
    storage: {
        pdf: 'https://qa.staticstorage.lunanuevaportal.com/Terms and Conditions/Condiciones y políticas de seguridad.pdf'
    },
    country: {
        telephonePrefix: "+502"
    },
    countryUtilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    portalInmobiliarioLuna: "https://dev.lunanuevaportal.com/"
};
 