export const locale = {
	lang: 'es',
	data: {
		"general": {
			"labelEmail": "Correo electrónico",
			"labelUserProfile": "Perfil de Usuario",
			"labelIndicateType": "Indicar si es inmobiliaria, vendedor, o propietario",
			"textVirtualReality": "Realidad virtual",
			"textUsed": "De uso",
			"textNew": "Nuevo",
			"labelRent": "en renta",
			"labelSale": "en venta",
			"textBackHome": "Volver al Home"
		},
		"header": {
			"linkContact": "Contáctanos",
			"linkServices": "Servicios",
			"linkCreateAccount": "Crea tu cuenta",
			"linkLogin": "Iniciar sesión",
			"linkLogout": "Cerrar sesión",
			"linkMyAccount": "Mi cuenta",
			"linkMyData": "Mis datos",
			"linkMyFavorites": "Mis favoritos",
			"linkMyReserves": "Mis reservas",
			"btnPublishProperty": "Publicar propiedad",
			"textConfirmCloseSession": "¿Estás seguro que quieres cerrar sesión?",
			"textCloseSession": "Si, cerrar sesión",
			"textNoBack": "No, volver",
			"textTooltip": "Te faltan datos por completar en la sección “Mi cuenta”."
		},
		"headerMenu": {
			"textSignIn": "Crear cuenta",
			"textMyAccount": "Mi cuenta",
			"textMyReservations": "Mis reservas",
			"textMyFavourites": "Mis favoritos",
			"textUs": "Nosotros",
			"textServices": "Servicios",
			"textLogIn": "Iniciar sesión",
			"textLogOut": "Cerrar sesión"
		},
		"footer": {
			"text1": "Gestiona tus reservas de alojamiento de forma sencilla y personalizada en Guatemala con nosotros.",
			"text2": "personalizada en Guatemala con nosotros.",
			"linkUs": "Nosotros",
			"linkDevelopedBy1": "Desarrollado con",
			"linkDevelopedBy2": "por Seiza. Todos los derechos reservados",
			"linkContact": "Contáctanos",
			"linkServices": "Servicios",
			"linkPublishProperty": "Publica tu propiedad"
		},
		"home": {
			"bannerTitle": "¡Bienvenido a ",
			"bannerTitle2": "Luna Nueva Properties!",
			"bannerText": "Reserva tu alojamiento ideal en Antigua Guatemala.",
			"bannerFirst": "Alojamientos",
			"bannerSecond": "Selecciona fechas para tu alojamiento",
			"btnContactUs": "Contáctanos",
			"seeAll": "Ver todo",
			"allAccommodations": "Todos los alojamientos",
			"bannerPromoTitle": "¿Representas a una empresa y no encuentras en dónde hospedar a tus clientes en Antigua Guatemala?",
			"bannerPromoText": "¡Contáctanos! Podemos crear un paquete especial para tus necesidades."
		},
		"searchHeader": {
			"textProperties": "Propiedades",
			"textBuyingRenting": "¿Estás pensando en comprar o rentar? Revisa las propiedades que seleccionamos para ti"
		},
		"searchHeaderMobile": {
			"textSeeMoreFilter": "Ver más filtros"
		},
		"sortPosts": {
			"textOrderBy": "Ordenar por",
			"newest": "Más recientes",
			"lowerPrice": "Menor precio",
			"higherPrice": "Mayor precio",
			"older": "Más antiguas"
		},
		"searchBar": {
			"labelSearchByLocation": "Buscar por locación",
			"labelChooseOption": "Elige una opción",
			"labelPropertyType": "Tipo de propiedad",
			"labelPriceRange": "Rango de precios",
			"placeHolderSelectLocation": "Seleccionar locación",
			"placeHolderLike": "¿Qué te gustaría?",
			"placeHolderChooseProperty": "Elige tu propiedad",
			"placeHolderChooseRange": "Elige tu rango de precios",
			"subtitle": "Precios por noche sin incluir impuestos y tarifas",
			"btnSearch": "Buscar",
			"btnSearchInMap": "Buscar en mapa",
			"map": "Mapa",
			"textRent": "Rentar",
			"textBuy": "Comprar",
			"filters": "Filtros",
			"clearFilters": "Limpiar Filtros",
			"textDateTitle": "Fecha de llegada y salida",
			"textNights": "Noches",
			"textHint": "Fecha desde - hasta",
			"textChoose": "Agregar Fechas",
			"textSelect": "Selecciona fechas para tu alojamiento"
		},
		"guestSelector": {
			"textTitle": "Cantidad de huéspedes",
			"textButton": "Seleccionar huéspedes",
			"textGuests": "Huéspedes",
			"textAdults": "Adultos",
			"textChildren": "Niños",
			"textInfants": "Bebés",
			"textPets": "Mascotas",
			"textBottom1": "En Luna Nueva Properties también aceptamos ",
			"textBottom2": "Animales de Servicio",
			"textAge1": "Edad: 13 años o más",
			"textAge2": "Edad: De 2 a 12 años",
			"textAge3": "Edad: De 0 a 2 años"
		},
		"searchOption": {
			"textTitle": "Elige el tipo de lugar de tu preferencia:",
			"textApartment": "Apartamento",
			"textHouse": "Casa",
			"textBed&Breakfast": "Bed and Breakfast"
		},
		"rangePrice": {
			"from": "Desde",
			"to": "Hasta"
		},
		"rentOptions": {
			"rent": "Tiempo de estadía",
			"one_month": "Un Mes",
			"three_months": "Tres Meses",
			"more_than_three_months": "Más de 3 Meses",
			"one_year": "1 Año"
		},
		"modalPets": {
			"title": "Animales de servicio",
			"text1": "La Ley de Protección y Bienestar Animal de Guatemala, Decreto 5-2017, reconoce a los animales de servicio como una forma de apoyo para las personas con discapacidades.",
			"text2": "La ley establece que los animales de servicio tienen derecho a acceder a lugares públicos, como restaurantes, tiendas y transporte público.",
			"close": "Cerrar"
		},
		"modalHints": {
			"title": "Tarifa por servicio de Luna Nueva",
			"text1": "En Luna Nueva destinamos una parte de nuestros recursos para apoyar a artesanos y artistas independientes de Guatemala, brindándoles capacitación y soporte para promover sus productos alrededor del mundo.",
			"close": "Cerrar"
		},
		"modalID": {
			"title": "Agregar documento de identidad",
			"text1": "Para finalizar la reserva, es necesario que nos envíes tu Documento de Identidad, el cual debe tener una vigencia de 3 meses.",
			"text2": "No te preocupes, mantendremos la protección de tu identidad de acuerdo con nuestra política de privacidad.",
			"text3": "Solo utilizaremos tu documento de identidad en caso de ser necesario, por ejemplo, para verificar tu edad o para realizar un cargo en tu tarjeta de crédito.",
			"close": "Entendido"
		},
		"card": {
			"labelSeeMore": "Ver más",
			"labelBedroom": "habitación",
			"labelBedrooms": "habitaciones",
			"labelBed": "Cama",
			"labelBathroom": "baño",
			"labelBathrooms": "baños",
			"linkDoTour": "Hacer tour 360º",
			"linkVirtualReality": "Realidad Virtual",
			"textPostDeactivated": "Publicación desactivada",
			"textPostNoAvailable1": "¡Lo sentimos! Esta publicación ya no",
			"textPostNoAvailable2": "está disponible, puede que se haya",
			"textPostNoAvailable3": "vendido o haya sido desactivada.",
			"textDelete": "Eliminar",
			"labelIn": "en",
			"labelHouse": "Casa",
			"labelApartment": "Apartamento",
			"labelBedAndBreakFast": "Habitación",
			"labelSuite": "Suite",
			"labelDiscount": "Oferta",
			"labelPricePerNight": "Precio por noche",
			"labelPrice": "Precio ",
			"labelFor": "por",
			"labelNight": "noche",
			"labelNights": "noches",
			"labelAdults": "Adultos",
			"labelAdult": "Adulto",
			"labelGuest": "Huésped",
			"labelGuests": "Huéspedes",
			"labelTaxes": "Incluye impuestos y cargos",
			"textDistance1": "A",
			"textDistance2": "del centro",
			"textFromPrice": "Precios desde",
			"textBooking": "Reservar",
			"textTarifaLimpieza": "Tarifa de limpieza",
			"textTarifaLunaNueva": "Tarifa por servicio de Luna Nueva",
			"textTotalImpuestos": "Total (impuestos incluídos)",
			"textTotalImpuestosOferta": "Total oferta (impuestos incluídos)",
			"textTooltip": "En Luna Nueva Properties destinamos una parte de nuestros recursos para apoyar a artesanos y artistas independientes en Guatemala.",
			"textMoreInfo": "Más información"
		},
		"cardMap": {
			"labelBathrooms": "baños"
		},
		"servicesForm": {
			"back": "Volver",
			"labelContactForm": "Formulario de contacto",
			"labelFullName": "Nombre y apellido",
			"labelTelephone": "Teléfono",
			"textProfileType": "Arrendador / Comprador",
			"labelOptionToBeContacted": "Elige una opción por la que te gustaría ser contactado",
			"labelSendMeACopy": "Envíame una copia",
			"btnSend": "Enviar",
			"labelTour": "Servicios tour 360º",
			"labelVirtualReality": "Servicios realidad virtual",
			"labelPhotography": "Servicios fotografía",
			"labelLegalServices": "Servicios legales",
			"labelSellProperty": "Vendemos tu propiedad",
			"labelRentProperty": "Rentamos tu propiedad",
			"labelPostProperty": "Publica tu propiedad",
			"labelMessage": "Mensaje",
			"labelManagement": "Administración y gestión de propiedades",
			"labelDesign": "Diseño de interiores",
			"labelAnotherRequest": "Otra solicitud",
			"labelDescribe": "Describe porque te gustaría ser contactado"
		},
		"fieldInput": {
			"textSaveContinue": "Guardar y Continuar",
			"textContinue": "Continuar",
			"textCancel": "Cancelar",
			"textLoading": "Cargando...",
			"msgRequiredField": "Campo requerido",
			"textInvalidEmailFormat": "Formato de email inválido",
			"textInvalidDpiFormat": "Formato de DPI inválido",
			"msgYahooEmailNotAllowed": "Correos de Yahoo no son permitidos. Use otro proveedor.",
			"msgOnlyNumbers": "Debes elegir un código de área y asegurarte que el número sea correcto.",
			"labelPassword": "Contraseña",
			"labelRepeatPassword": "Repetir contraseña",
			"msgPasswordError": "La contraseña debe contener: 8 caracteres como mínimo, una letra mayúscula y un número.",
			"msgPasswordMatch": "Las contraseñas deben coincidir",
			"msgPassword": "Debe contener mínimo 8 caracteres, una mayúscula y un número",
			"labelName": "Nombre",
			"lastName": "Apellido",
			"labelDpiPassport": "Número de documento",
			"labelDocumentType": "Tipo de documento",
			"textSave": "Guardar",
			"textPassport": "Pasaporte"
		},
		"register": {
			"textTitle": "Completa tus datos",
			"textMyAccount": "Mi cuenta",
			"textPersonalInformation": "Datos personales",
			"msgName": "Ingrésalo como aparece en tu documento",
			"textTermAndConditions1": "Acepto los ",
			"textTermAndConditions2": "Términos y Condiciones ",
			"textTermAndConditions3": "y autorizo el uso de mis datos de acuerdo a la ",
			"textTermAndConditions4": "Declaración de Privacidad",
			"ex": "Ex",
			"labelNationality": "Nacionalidad",
			"msgAccessToEmail": "Asegúrate de tener acceso a este correo electrónico",
			"msgDpiPassport": "Seleccionar DPI o Pasaporte",
			"msgNationality": "Seleccionar nacionalidad",
			"msgDocument": "Escribe el número de tu DPI o pasaporte Ej.: 2320 55634 0103",
			"buttonText": "Cerrar",
			"textHeader": "La solicitud ha sido enviada a tu correo electrónico",
			"textBody": "Dirígete a tu bandeja de entrada para validar tu correo. Si no lo encuentras revisa tu carpeta de correos no deseados",
			"infoConditions": "Debe aceptar los Términos y Condiciones.",
			"infoError": "No hemos podido registrar el usurio, intentelo nuevamente.",
			"infoFormInvalid": "Para continuar debes llenar todos los campos del formulario",
			"infoFormInvalidData": "Luna Nueva no hará uso de tus datos sin habértelo consultado previamente",
			"infoErrorUserExist": "Ya existe una cuenta con el correo electrónico ",
			"infoErrorUserExistData": "Si tienes una cuenta en Luna Nueva Properties, no necesitas crear una nueva, inicia sesión con ese correo.",
			"textButtonUserExist": "Usar otro correo",
			"textButtonUserExistInitialSession": "Iniciar sesión",
			"textInformation": "Información",
			"textPhoneForm": "Lo usaremos solo en caso de solicitar información adicional.",
			"textAlerInformation": "Guarda tus datos para reservar más rápido la próxima vez.",
			"textIdUpload": "Agregar documento de identidad",
			"textHover": "Para la reserva será necesario este documento de identidad.",
			"textFormat": "Formatos JPG, PNG o PDF con un peso máximo de 6MB",
			"textFieldRequired": "Campo requerido",
			"textSee": "Ver",
			"textDelete": "Eliminar",
			"interior": {
				"id": "my-account-desing",
				"title": "Mi cuenta",
				"breadCrumb": "Mi cuenta"
			},
			"textFileSuccess": "Archivo cargado con éxito",
			"textFileError": "Error al cargar archivo",
			"textDataUpdated": "Datos actualizados",
			"textDataError": "Error al actualizar datos",
			"textUploading": "Subiendo Archivo..."
		},
		"signIn": {
			"textWelcome": "¡Bienvenido! Ingresa tu correo electrónico",
			"textRestorePwd": "Recupera tu contraseña",
			"textCreateAccount": "Crear cuenta",
			"textIncorrectData": "Datos Incorrectos",
			"textInvalidCredentials": "Credenciales Inválidas",
			"verifyEmail": "Verifica tu correo electrónico"
		},
		"emailVerification": {
			"textVerifyingEmail": "¡Estamos verificando su correo electrónico, espere por favor.",
			"textCongratulations": "Felicidades, validamos tu correo electrónico",
			"textDescription": "Ahora puedes ingresar a la plataforma con tu cuenta.",
			"textError": "Error Verificando",
			"textErrorDescription": "Ocurrió un error verificando su correo."
		},
		"emailSendVerification": {
			"helpVerifyEmail": "Ayuda para verificar tu correo electrónico",
			"textAccountEmail": "Escribe la dirección de correo electrónico asociado a tu cuenta de Luna Nueva Properties",
			"textSuccessRequest": "La solicitud de validar correo ha sido enviada a tu correo electrónico",
			"textGoToInbox": "Dirígete a tu bandeja de entrada y sigue las instrucciones para validar tu cuenta.",
			"textErrorRequest": "Error enviando solicitud de validar correo",
			"textErrorDescription": "Ocurrió un error verificando su correo."
		},
		"forgotPassword": {
			"textHelpWithPwd": "Ayuda con la contraseña",
			"textAccountEmail": "Escribe la dirección de correo electrónico asociado a tu cuenta de Luna Nueva Properties",
			"textGoToInbox": "Dirígete a tu bandeja de entrada y sigue las instrucciones para restablecer tu contraseña.",
			"textSuccessRequest": "La solicitud de recuperar contraseña ha sido enviada a tu correo electrónico",
			"textErrorRequest": "Error enviando solicitud de recuperar contraseña",
			"textErrorDescription": "Un error ha ocurrido al enviar la solicitud de recuperar contraseña",
			"textEmailNotFound": "El correo electrónico no fue encontrado",
			"helpVerifyEmail": "Ayuda para verificar tu correo electrónico"
		},
		"resetPassword": {
			"textRestorePwd": "Reestablecer contraseña",
			"textNewPwd": "Escribe una nueva contraseña",
			"textSave": "Guardar",
			"textError": "Error cambiando contraseña",
			"textErrorDescription": "Puede que este enlace haya expirado"
		},
		"filtersSideMenu": {
			"textApartmentsForSale": "Apartamentos en venta",
			"textPublishedToday": "Publicados hoy",
			"textTerrace": "Terraza",
			"textGarden": "Jardín",
			"textBedroom": "dormitorio",
			"textBedrooms": "dormitorios",
			"textBathroom": "baño",
			"textBathrooms": "baños",
			"textCondition": "Condición",
			"textStudio": "Monoambiente",
			"textMore": "o más",
			"textNew": "Nuevo",
			"textUsed": "Usado",
			"textTypeOfLease": "Tipo de arriendo",
			"textTraditional": "Tradicional",
			"textNoParking": "No tiene parking",
			"textInsideParking": "Dentro del alojamiento",
			"textOutsideParking": "Fuera del alojamiento",
			"textFilterBy": "Filtrar por",
			"textWorkspace": "Área de trabajo",
			"textWifi": "Wifi",
			"textPool": "Piscina",
			"textSmokerFriendly": "Apto para fumadores",
			"textParkingInTheAccommodation": "Parking",
			"textOtherServices": "Otros servicios",
			"textWashingMachine": "Lavadora",
			"textDryerMachine": "Secadora",
			"textKitchen": "Cocina",
			"textFridge": "Nevera",
			"textTv": "Tv",
			"textMicrowave": "Microondas",
			"textBalcony": "Balcón",
			"textHeating": "Calefacción",
			"textAirConditioner": "Aire acondicionado",
			"textSofa": "Sofá Cama",
			"textElevatorEntrance": "Acceso al ascensor",
			"textHairDryer": "Secadora de cabello",
			"textIron": "Plancha",
			"textGrill": "Grill",
			"textSecurity": "Seguridad",
			"textSmokeDetector": "Detector de humo",
			"textCarbonMonoxideDetector": "Detector de monóxido de carbono",
			"textApplyFilters": "Aplicar filtros",
			"textClearFilters": "Limpiar filtros",
			"textBeds": "Camas",
			"text1Bed": "1 cama",
			"text2BedsOrMore": "2 camas o más",
			"textQueenBed": "Cama queen",
			"textKingBed": "Cama king",
			"textIndividualBed": "Cama individual",
			"textMatrimonialBed": "Cama matrimonial",
			"textSharedBathroom": "Baño compartido",
			"textPrivateBathroom": "Baño privado",
			"textMeditationArea": "Zona de meditación"
		},
		"filtersSideNavMobile": {
			"labelAdditionalFilters": "Filtros adicionales",
			"labelSelectFilters": "Selecciona los filtros para tu búsqueda",
			"labelApplyFilters": "Aplicar filtros"
		},
		"postDetails": {
			"textBack": "Volver al listado",
			"textPublished": "Anfitrión : Luna Nueva Properties",
			"textAgo": "hace",
			"textBy": "por",
			"textByNight": "noche",
			"textPetFriendly": "Pet-Friendly",
			"linkDoTour": "Hacer tour 360º",
			"textContactSeller": "Contactar al vendedor",
			"textBreakfastIncluded": "Esta tarifa incluye desayuno",
			"textBreakfastNotIncluded": "Esta tarifa no incluye desayuno",
			"textOrientation": "Orientación :",
			"textParking": "Estacionamiento gratis(solicitar disponibilidad)",
			"textNoParking": "Sin estacionamiento",
			"textShare": "Compartir",
			"textShareInstagram": "Compartir en Instagram",
			"textShareFacebook": "Compartir en Facebook",
			"textShareWhatsapp": "Compartir en Whatsapp",
			"textInstagramMessage": "Se ha copiado el link de Luna nueva para que lo compartas en Instagram",
			"textShareCopy": "Copiar",
			"textContact": "Contáctanos",
			"textLocation": "Ubicación",
			"textWorkspace": "Zona de trabajo",
			"textThereIsWorkingZone": "Una habitación con wifi que es ideal para trabajar",
			"textThereIsNotWorkingZone": "No tiene wifi",
			"textOneGuest": "Huésped",
			"textManyGuests": "Huéspedes",
			"textCancelationTime": "Cancelación gratuita: Gratis 3 días antes de la reserva",
			"textContactInfo": "Podrás contactarnos si deseas tener más información",
			"textDescription": "Descripción",
			"textOtherCharacteristics": "Otras características",
			"textBBServices": "Lo que ofrece este alojamiento",
			"textServices": "Lo que ofrece esta propiedad ",
			"textSharedAreas": "Áreas comunes",
			"textYouMustKnow": "Lo que debes saber",
			"textBBPolitics": "Políticas del Bed and Breafast",
			"textPolitics": "Políticas del alojamiento",
			"textZoneInfo": "Información de la zona",
			"labelBedroom": "habitación",
			"labelBedrooms": "habitaciones",
			"labelBathroom": "baño",
			"labelBathrooms": "baños",
			"textGarden": "Con jardín",
			"textTerrace": "Con terraza",
			"textYear": "año",
			"textYears": "años",
			"textMonth": "mes",
			"textMonths": "meses",
			"textDay": "día",
			"textDays": "días",
			"textHour": "hora",
			"textHours": "horas",
			"textMinute": "minuto",
			"textMinutes": "minutos",
			"textNorth": "Norte",
			"textSouth": "Sur",
			"textEast": "Este",
			"textWest": "Oeste",
			"textPaidServices": "Servicios con costo adicional",
			"textPaidServicesContact1": "Estos servicios tienen un costo adicional, ",
			"textPaidServicesContact2": "contáctanos ",
			"textPaidServicesContact3": "si deseas tener más información",
			"parkingDentro": "Incluye parking dentro del alojamiento (solicitar disponibilidad)",
			"parkingFuera": "Incluye parking fuera del alojamiento (solicitar disponibilidad)"
		},
		"postDetailServices": {
			"textGarden": "Jardín",
			"textTerrace:": "Terrace",
			"textPetFriendly": "Pet-Friendly",
			"textPool": "Piscina",
			"textWifi": "Wifi",
			"textSmokerFriendly": "Apto para fumadores",
			"textLaundry": "Lavadora",
			"textKitchen": "Cocina",
			"textTv": "Tv",
			"textStreaming": "Streaming",
			"textDanceFloor": "Pista de baile",
			"textSmartTv": "Smart Tv",
			"textSmokerNotFriendly": "No se permite fuma",
			"textCloset": "Armario",
			"textToiletPaper": "Papel higiénico",
			"textTowels": "Toallas",
			"textToiletries": "Artículos de aseo gratis",
			"textAirConditioner": "Aire acondicionado",
			"textHeating": "Calefacción",
			"textElectricalSocket": "Enchufe cerca de la cama",
			"textHairDryer": "Secadora de cabello",
			"outsideParking": "Estacionamiento exterior",
			"insideParking": "Estacionamiento interior",
			"cookingCourses": "Cursos de cocina",
			"textBreakfast": "Desayuno",
			"yoga": "Yoga",
			"culturalTours": "Tours culturales",
			"textChef": "Chef",
			"textMassage": "Masaje",
			"textDriver": "Chofer",
			"textCulturalTalks": "Charlas culturales",
			"textIron": "Plancha",
		},
		"postDetailsBBCommonAreas": {
			"textGarden": "Jardín",
			"textTerrace": "Terraza",
			"textTv": "Tv",
			"textPool": "Piscina",
			"textWifi": "Wifi",
			"textSmokeZone": "Zona de fumadores",
			"textLaundry": "Lavandería",
			"textKitchen": "Cocina",
			"textWorkspace": "Área de trabajo"
		},
		"postMustKnow": {
			"textCheckIn": "El check in es a partir de las",
			"textCheckOut": "La salida es antes de las",
			"textCleaningService": "Limpieza diaria y cambio de toallas",
			"textOneGuest": "1 huésped como máximo",
			"textMaxGuests": "huéspedes como máximo",
			"textPetFriendly": "Se admiten mascotas",
			"textNoParties": "Está prohibido hacer fiestas o eventos",
			"textComercialPhoto": "Fotografía comercial permitida",
			"textKeyReturn": "Entrega las llaves en recepción",
			"textNoConciergeTip": "No está incluida la propina al conserje",
			"textSmartLock": "Llegada autónoma con cerradura inteligente"
		},
		"propertyCharacteristics": {
			"textCharacteristics": "Características",
			"totalArea": "Superficie total",
			"textUsefulSurface": "Superficie útil",
			"textTerraceArea": "Superficie de terraza",
			"textBedrooms": "Cuartos",
			"textBathrooms": "Baños",
			"textMaximumNumberOfInhabitants1": "Cantidad máxima",
			"textMaximumNumberOfInhabitants2": "de habitantes",
			"textUnitFloorNumber1": "Número de piso",
			"textUnitFloorNumber2": "de la unidad",
			"textApartmentsPerFloor1": "Apartamentos",
			"textApartmentsPerFloor2": "por piso",
			"textNumberOfFloors": "Cantidad de pisos",
			"textCondition": "Condición",
			"textOrientation": "Orientación",
			"textRealStateInformation": "Información de la inmobiliaria",
			"textGotoRealStatePro": "Ir al perfil de la inmobiliaria",
			"textViewMore": "Ver más publicaciones de",
			"textPropertyCode": "Código de la propiedad",
			"textShare": "Compartir"
		},
		"propertyRequirements": {
			"textRequirements1": "Requerimientos para ",
			"textRequirements2": "esta propiedad",
			"textShowIncome": "Muestra tus ingresos:",
			"textDocumentsWeNeed": "Documentos que necesitamos:",
			"textRent": "rentar",
			"textBuy": "comprar"
		},
		"propertyReservation": {
			"textNight": "noche",
			"textNights": "noches",
			"textCleaningFee": "Tarifa de limpieza",
			"textLunaNuevaService": "Tarifa por servicio de Luna Nueva",
			"textGoBack": "Volver",
			"textSaveAndSend": "Guardar datos y enviar solicitud de reserva",
			"textYourData": "Tus datos",
			"textContactUs": "¿Dudas o preguntas? Contáctanos",
			"textTitle": "Confirmación y datos",
			"textYourTrip": "Tu viaje"
		},
		"reservationSuccess": {
			"textTitle": "La solicitud de reserva ha sido enviada con éxito",
			"textToReservations": "Llévame a mis reservas"
		},
		"reservationError": {
			"title": "No pudimos enviar la solicitud de reserva.",
			"text": "Por favor inténtalo nuevamente.",
			"retry": "Reintentar"
		},
		"reservationPriceChanged": {
			"textTitle": "Cambio de precio",
			"text1": "Te escribimos para informarte que tu reserva tiene un cambio de precio:",
			"textOldPrice": "Antes",
			"textNewPrice": "Ahora",
			"textPerNight": "por noche",
			"text2": "Este cambio se debe a las actualizaciones realizadas dentro de la propiedad",
			"text3": "Ten en cuenta esta información antes de continuar con tu solicitud de reserva",
			"textUnderstood": "Entendido"
		},
		"successfulContact": {
			"textTitle": "El formulario ha sido enviado con éxito",
			"text1": "En breve te contactaremos para atender tu solicitud y responder todas tus preguntas.",
			"close": "Cerrar"
		},
		"characteristicsList": {
			"textNoCharacteristics": "Esta propiedad todavía no tiene características"
		},
		"postsNotFound": {
			"textNoProperties1": "No existen propiedades con las características",
			"textNoProperties2": "solicitadas, por favor modifica tu búsqueda e",
			"textNoProperties3": "intenta nuevamente"
		},
		"bbProfile": {
			"descriptionTitle": "Descripción del Bed and Breakfast",
			"publications": "publicaciones",
			"publication": "publicación",
			"viewAll": "Ver todo",
			"contact": "Contáctanos"
		},
		"reservations": {
			"title": "Mis reservas",
			"canceledTitle": "Reservas canceladas",
			"path": "Mi cuenta > Mis reservas",
			"placesYouvebeen": "Lugares donde estuviste",
			"subtitle": "Tu estadía en",
			"arrival": "Llegada",
			"departure": "Salida",
			"location": "Ubicación",
			"reservationNumber": "Número de reserva",
			"cancelationPolicy": "Política de cancelación",
			"cancelationPolicyDetail": "Cancelación gratuita: Gratis al cancelar 3 días antes de la fecha de reserva",
			"contact": "Contáctanos",
			"contactComplement": " si deseas tener más información",
			"paymentInformation": "Información de pago",
			"totalCost": "Costo total",
			"receiptDownload": "Descargar recibo",
			"buttonTitle": "Ver el alojamiento",
			"textYouMustKnow": "Lo que debes saber",
			"politics": "Politicas de",
			"noReservation": "Todavía no tienes reservas",
			"noReservation2": "¡Comienza a crear una ahora!",
			"confirmed": "Confirmado",
			"pending": "Por confirmar",
			"cancelled": "Cancelada",
			"seeMore": "Ver más",
			"textBBPolitics": "Políticas del Bed and Breafast",
			"textPolitics": "Políticas del alojamiento"
		},
		"realState": {
			"posts": " publicaciones",
			"found": "Encuentra tu corredor de propiedades favorito: ",
			"properties": "Ver Propiedades"
		},
		"realStateBAB": {
			"posts": " publicaciones",
			"found": "Encuentra tu Bed and Breakfast favorito: ",
			"properties": "Ver Propiedades",
			"viewAll": "Ver todos los Bed and Breakfast"
		},
		"googleMap": {
			"textExist": "Existen",
			"textPropertyInArea": " propiedades en esta área",
			"textNoProperties": "No existen propiedades en esta área"
		},
		"favorites": {
			"path": "Mi cuenta > Favoritos",
			"textFavorites": "Favoritos",
			"textNoFavorites": "No tienes alojamientos favoritos",
			"textBackHome": "Volver"
		},
		"notFound": {
			"textPageNotFound": "¡Ups! No se encuentra esta página",
			"textDeletedPage": "Es posible que se haya eliminado la página que buscas"
		},
		"services": {
			"textServices": "Servicios",
			"textMore": "Más información",
			"tour-360": {
				"title": "Tour 360º",
				"titleIframe": "Demostración del Tour 360º",
				"textOne": " Elimina las barreras de espacio o de tiempo con tus clientes potenciales",
				"textTwo": " Diseño, producción y realización de recorridos virtuales 360º de alto impacto para casas, apartamentos, edificios y otros inmuebles",
				"subtitle": "Elimina las barreras de espacio o de tiempo con tus clientes potenciales",
				"paragraphs": {
					"first": "Diseño, producción y realización de recorridos virtuales 360º de alto impacto para casas, apartamentos, edificios y otros inmuebles.",
					"second": "Con nuestros recorridos virtuales en 360",
					"third": "Con nuestros recorridos virtuales en 360 tus futuros compradores, inquilinos o el inversionista recorrerán tu propiedad en cualquier momento desde cualquier dispositivo electrónico (computadora / smartphone / tableta)."
				},
				"subtitleTwo": "Algunos beneficios:",
				"list": {
					"item1": "Se eliminan las limitaciones de tiempo y ubicación de compradores potenciales.",
					"item2": "Aumenta la confianza sobre tu portafolio",
					"item3": "Aumenta el alcance de tu negocio, pues permite que todos los usuarios puedan ver lo que vendes. ",
					"item4": "Eleva las posibilidades de vender, aumentando el número de visitas a los inmuebles. ",
					"item5": "Reduce tiempo en las transacciones comerciales, pues ni el cliente ni el corredor inmobiliario tienen que hacer ",
					"item6": "coincidir su agenda para realizar la visita.",
					"item7": "Disminuye los costos y esfuerzos el agente inmobiliario, pues no debe dedicar tiempo en la visita o gastos para el desplazamiento.",
					"item8": "Aumenta la productividad de los agentes inmobiliarios y de la empresa.",
					"item9": "Es compatible con los diferentes canales digitales más recomendados para la comercialización de inmuebles."
				}
			},
			"vr": {
				"title": "Realidad virtual",
				"textOne": "Presenta a tus inversionistas su futuro proyecto de inversión desde cualquier lugar y en cualquier momento,  sin depender de un avance físico",
				"textTwo": "Con este recorrido inmersivo tu cliente podrá moverse por las instalaciones a su gusto, visitar cada área e interactuar con objetos del entorno sin moverse de tu oficina.",
				"subtitle": "Presenta a tus inversionistas su futuro proyecto de inversión desde cualquier lugar y en cualquier momento, sin depender de un avance físico",
				"paragraphs": {
					"first": "Con este recorrido inmersivo tu cliente podrá moverse por las instalaciones a su gusto, visitar cada área e interactuar con objetos del entorno sin moverse de tu oficina. El recorrido virtual utilizando la tecnología RV es una herramienta estratégica de alto impacto para vender proyectos inmobiliarios a inversionistas y clientes potenciales pues puedes explicar el proyecto como nunca antes lo habías hecho. "
				},
				"subtitleTwo": "Algunos beneficios:",
				"list": {
					"item1": "Reducir tiempo y dinero al iniciar un desarrollo para empezar a generar ventas.Presenta el proyecto sin depender de avances físicos.",
					"item2": "Mejora el contacto con tus inversionistas.Tus inversionistas podrán observar con detenimiento que cambios se hacen al diseño del proyecto, además de seguir el desarrollo inmobiliario.",
					"item3": "Eleva las posibilidades de vender, aumentando el número de visitas a los inmuebles. ",
					"item4": "Las visitas virtuales son una clara ventaja competitiva ya que eliminan la barrera de tiempo y distancia.",
					"item5": "Visualización total del espacio en el desarrollo inmobiliario.Se incrementa la experiencia de la vivienda y es posible dar una continua retroalimentación para finar detalles del proyecto."
				},
				"titleIframe": "Demostración de Realidad Virtual"
			},
			"photography": {
				"title": "Fotografía",
				"textOne": "Dale una presentación adecuada a tu propiedad e impulsa su renta o venta",
				"textTwo": " La fotografía forma parte del marketing emocional y resulta muy útil para motivar a tus clientes potenciales.",
				"subtitle": "Dale una presentación adecuada a tu propiedad e impulsa su renta o venta ",
				"paragraphs": {
					"first": "La fotografía forma parte del marketing emocional y resulta muy útil para motivar a tus clientes potenciales.",
					"second": "La fotografía inmobiliaria es un recurso visual para emocionar desde la primera etapa del proceso de venta. Ésta, motiva el recorrido mental del inmueble, beneficios y vivencias que se pueden dar en cada espacio."
				}
			},
			"legalServices": {
				"title": "Servicios legales",
				"textOne": "Nuestro equipo legal especializado en bienes raíces para extranjeros y nacionales les ayudará con los siguientes servicios:",
				"textTwo": "Asesoría y elaboración de opiniones legales relacionadas a negociación y contratación de proyectos inmobiliarios, acuerdos de arrendamiento de propiedades, garantías inmobiliarias, entre otros. ",
				"subtitle": "Nuestro equipo legal especializado en bienes raíces para extranjeros y nacionales les ayudará con los siguientes servicios:",
				"list": {
					"item1": "Asesoría y elaboración de opiniones legales relacionadas a negociación y contratación de proyectos inmobiliarios, acuerdos de arrendamiento de propiedades, garantías inmobiliarias, entre otros.",
					"item2": "Investigación registral y catastral de inmuebles.",
					"item3": "Análisis y formalización de compraventas y otras formas de transferencia",
					"sublistItem3": {
						"item1": "Arrendamientos",
						"item2": "Usufructos",
						"item3": "Hipotecas",
						"item4": "Otro tipo de gravámenes"
					},
					"item4": "Planificación de estrategia de venta legal/fiscal del proyecto inmobiliario.",
					"item5": "Constitución de régimen de propiedad horizontal, en condominio o cualquier otro que se requiera, y elaboración de sus respectivos reglamentos"
				}
			},
			"saleRent": {
				"title": "Vendemos y rentamos tu propiedad",
				"textOne": "Vendemos tu inmueble de una forma segura, buscando al cliente adecuado a tus necesidades y seleccionándolos con intención de compra",
				"textTwo": "Nuestro equipo de ventas se encargará de conseguir al cliente ideal para la renta o venta de tu propiedad.",
				"subtitle": "Vendemos y rentamos tu inmueble de una forma segura, buscando al cliente adecuado a tus necesidades y seleccionándolos con intención de compra",
				"paragraphs": {
					"first": "Nuestro equipo de ventas se encargará de conseguir al cliente ideal para la renta o venta de tu propiedad.  ",
					"second": "¿Cuánto cobramos?  "
				},
				"textSale": "Venta",
				"textValueOfSale": "del valor de la venta ",
				"textRent": "Renta",
				"textValueFirstRent": "del valor de la primera renta."
			},
			"management": {
				"title": "Administración y gestión de propiedades",
				"textOne": "¿Tienes una o varias propiedades en Antigua Guatemala y te falta tiempo para encargarte de ellas?",
				"textTwo": "No te preocupes, estamos aquí para ayudarte. Nuestro servicio de administración y gestión de propiedades está diseñado para propietarios ocupados como tú...",
				"subtitle": "¿Tienes una o varias propiedades en Antigua Guatemala y te falta tiempo para encargarte de ellas?",
				"paragraphs": {
					"first": "No te preocupes, estamos aquí para ayudarte. Nuestro servicio de administración y gestión de propiedades está diseñado para propietarios ocupados como tú, que desean maximizar los ingresos sin tener que preocuparse por la tediosa tarea de administrar su propiedad. Desde la comunicación con los huéspedes hasta el manejo eficiente de plataformas de hospedaje como Airbnb, nos aseguramos de que tu propiedad esté siempre visible y atraiga a los viajeros adecuados.",
					"second": "Nuestro equipo se encargará de responder consultas, gestionar reservas y brindar atención excepcional a los huéspedes para garantizar una experiencia de hospedaje inolvidable. Además, nos ocupamos de los aspectos relacionados con la limpieza y mantenimiento de tu propiedad. Nos aseguraremos de que cada rincón esté impecable y listo para recibir a los huéspedes.",
					"third": "Incluso si tu propiedad no está en plataformas de hospedaje y la tienes disponible para rentas de corto, mediano o largo plazo, nuestro servicio sigue siendo la solución perfecta. Nos encargamos de todas las tareas, desde encontrar clientes para la renta de tu propiedad y su administración hasta la gestión de contratos y cobros.",
					"fourth": "Libérate del estrés y deja que nuestro equipo experto en administración y gestión de propiedades se ocupe de todo. Nos aseguramos de que tu propiedad sea una fuente de ingresos sin complicaciones mientras disfrutas de tu tiempo libre."
				}
			},
			"interiorDesign": {
				"title": "Diseño de interiores",
				"textOne": "¿Tienes un 80% del diseño de tu casa lista, pero sientes que aún falta ese encanto exclusivo, ese 'je-ne-sais-quoi' indescriptible?",
				"textTwo": "No te preocupes, estamos aquí para aportar los toques finales que harán que tu hogar cobre vida de verdad! Nuestros servicios de estilismo van más allá de lo convencional y se adentran en la meticulosa búsqueda de esos elementos finales.",
				"subtitle": "¿Tienes un 80% del diseño de tu casa lista, pero sientes que aún falta ese encanto exclusivo, ese 'je-ne-sais-quoi' indescriptible?",
				"paragraphs": {
					"first": "No te preocupes, estamos aquí para aportar los toques finales que harán que tu hogar cobre vida de verdad! Nuestros servicios de estilismo van más allá de lo convencional y se adentran en la meticulosa búsqueda de esos elementos finales y distintivos, desde elementos decorativos seleccionados a mano hasta piezas de diseño cuidadosamente escogidas y cautivadoras obras de arte, que añaden carácter y personalidad a tu espacio vital.",
					"second": "Nuestra estructura de precios es sencilla: nuestros servicios están disponibles a una tarifa de $100 por hora (sin incluir impuestos), con un compromiso mínimo de 4 horas para cada proyecto. Adaptamos nuestro enfoque en función de tus necesidades únicas, considerando el tamaño de tu hogar o las áreas específicas que requieren una transformación estilística, incluyendo la superficie total, el número de habitaciones e incluso las paredes que necesitan ese toque especial. Además, tenemos en cuenta tu presupuesto disponible para la búsqueda, de manera que podemos proporcionarte un presupuesto integral para nuestros servicios de diseño y un presupuesto estimado para la búsqueda, si aún no lo has definido.",
					"third": "Todo lo que te pedimos es que compartas un plano de tu hogar, así como fotos y videos detallados de las áreas que ansían nuestra infusión creativa. Ya sea que tu propiedad sea amplia y expansiva o íntima y acogedora, nuestra misión es convertirlo en un reflejo de tu personalidad y estilo únicos. Con nuestro toque experto, curaremos minuciosamente cada elemento para asegurarnos de que tu hogar irradie calidez, carácter y autenticidad.",
					"fourth": "Permítenos ser tus compañeros creativos en la travesía de elevar tu propiedad a su máximo potencial. ¡No dudes en contactarnos y permitirnos transformar tu hogar en una verdadera obra maestra!",
					"fifth": "Powered by STUDIO MACHTELD OOSTERBAAN"
				}
			}
		},
		"serviceDetail": {
			"backToList": "Volver al listado",
			"requestService": "Solicitar servicio"
		},
		"dialogLoginRegistSession": {
			"textCreate": "Para acceder a esta opción es necesario que previamente inicies sesión o te registres.",
			"textForLogin": "Para guardar propiedades en tu lista de favoritos es necesario que previamente inicies sesión o te registres",
			"textRegister": "Registrarme",
			"textSignupOrLogin": "Inicia sesión o regístrate"
		},
		"servicesFormDialog": {
			"buttonText": "Cerrar",
			"textHeader": "El formulario ha sido enviado con éxito",
			"textBody": "En breve te contactaremos para atender tu solicitud y responder todas tus preguntas",
			"buttonAccept": "Aceptar"
		},
		"contactPageForm": {
			"textErrorOccur": "Ocurrió un error",
			"textErrorExplanation": "Error inesperado. Por favor intente nuevamente",
			"textContactUs": "Contáctanos"
		},
		"contactUsForm": {
			"textTitle": "Contactános y resolveremos tus dudas inmediatamente",
			"textHelp": "¡Estamos aquí para ayudarte!",
			"textFormTitle": "Contáctanos",
			"textOption1": "Servicio de administración y gestión de propiedades",
			"textOption2": "Servicio de diseño de interiores",
			"textOption3": "Otra solicitud",
			"textDiscount": "Te daremos un 10% de descuento si completas este campo",
			"textRecommendedBy": "Recomendado por",
			"textSend": "Enviar",
			"textThisProperty": "Estás interesado en esta propiedad",
			"textCheckIn": "Fecha de llegada y salida",
			"textGuests": "Huéspedes"
		},
		"contactSellerPage": {
			"textContactSeller": "Contacta al vendedor",
			"textMessage": "Hola,¿Aún sigue disponible?",
			"textAccommodationMessage": "Hola, ¿Es fácil acceder a la propiedad desde el aeropuerto, estación de tren, o terminal de autobuses?"
		},
		"us": {
			"usTitle": "Quiénes somos",
			"usTexT": "En Luna Nueva, somos más que una empresa de real estate; somos una comunidad comprometida con el impacto social y el desarrollo cultural en el corazón de Antigua Guatemala. Nos dedicamos con pasión a la venta y renta de propiedades, la administración y gestión para reservas a corto plazo, y a brindar un servicio personalizado de acompañamiento en la compra y renta de propiedades. Con un enfoque profundo en el crecimiento de nuestra gente y en el apoyo a iniciativas locales, en Luna Nueva creemos que cada transacción y cada proyecto pueden dejar una huella positiva en nuestro entorno.",
			"usSubTitle": "Nuestro compromiso social",
			"usSubtitleTexts": "En Luna Nueva, cada servicio va acompañado de una misión: contribuir al desarrollo de nuestra comunidad. Este compromiso cobra vida a través de nuestro programa de administración de propiedades, diseñado para crear valor y bienestar en Antigua Guatemala:",
			"education": "Educación Gratuita para Nuestra Comunidad",
			"educationTexts": "Creemos que el conocimiento transforma vidas. A través de nuestros cursos en línea, ofrecemos a nuestro equipo y sus familias una oportunidad continua de crecimiento. Desde clases de inglés hasta nuevas habilidades, abrimos puertas para que cada miembro de nuestra comunidad desarrolle su potencial y alcance sus sueños.",
			"training": "Capacitación para Ofrecer Desayunos Tradicionales",
			"trainingTexts": "Celebramos la cultura guatemalteca desde el primer bocado del día. Hemos capacitado a nuestro equipo para que algunos de nuestros huéspedes puedan disfrutar de auténticos desayunos guatemaltecos, una experiencia que va más allá del turismo y que conecta a nuestros visitantes con las tradiciones del país. Con cada plato, promovemos las raíces de Guatemala y brindamos a nuestro equipo nuevas oportunidades de aprendizaje y crecimiento.",
			"art": "Espacios para el Arte Local",
			"artTexts": "Creemos en el poder del arte para contar historias y enaltecer la cultura. Por eso, en nuestras propiedades, hemos creado espacios donde artistas guatemaltecos pueden exhibir su trabajo. Queremos que quienes nos visiten conozcan la esencia de nuestro país y valoren el talento de nuestros creadores locales, quienes con sus obras reflejan la identidad y belleza de Guatemala.",
			"lunaNuevaPurpose": "Este es nuestro propósito en Luna Nueva:",
			"purposeSubtitle": "Generar experiencias memorables y construir un legado positivo en cada rincón de Antigua Guatemala.",
			"welcomeText": "Bienvenidos a ser parte de una comunidad que sueña, crece y construye juntos."
		},
		"weekdays": {
			"week": "Semana",
			"monday": "Lu",
			"tuesday": "Ma",
			"wednesday": "Mi",
			"thursday": "Ju",
			"friday": "Vi",
			"saturday": "Sa",
			"sunday": "Do"
		},
		"months": {
			"1": "Enero",
			"2": "Febrero",
			"3": "Marzo",
			"4": "Abril",
			"5": "Mayo",
			"6": "Junio",
			"7": "Julio",
			"8": "Agosto",
			"9": "Septiembre",
			"10": "Octubre",
			"11": "Noviembre",
			"12": "Diciembre"
		},
		"blog": {
			"home": {
				"TITLE": "Bienvenido al blog de Luna <br> Nueva portal inmobiliario",
				"SUBTITLE": "La información que necesitas para estar actualizado y tomar tu mejor decisión.",
				"LINKDETAIL": "Más información",
				"CARD1": {
					"TITLE": "Ventajas de vivir en Antigua Guatemala: la seguridad es lo más importante",
					"TEXT1": "Ven a vivir a Guatemala (o, más bien, a Antigua Guatemala); ¡te encantará!",
					"TEXT2": "Todo nuestro equipo vive aquí, y nos fascina. Te prometemos que te sentirás como en casa y que le contarás a todo el mundo lo bien que te lo estás pasando en el país de la eterna primavera.",
					"DATEPOST": "Publicado el 10 abril, 2023"
				},
				"CARD2": {
					"TITLE": "Entendiendo las particularidades de Antigua Guatemala: lo que nos hace únicos",
					"TEXT1": "Para nosotros, la era mexicana ha terminado, y ahora debemos descubrir lo que hay más allá. Como dice el refrán, \"ya he pasado por eso\" lo resume bastante bien.",
					"TEXT2": "Con la era de los nómadas digitales, se han abierto muchas oportunidades, ¡y el mundo es tuyo!",
					"DATEPOST": "Publicado el 10 abril, 2023"
				},
				"CARD3": {
					"TITLE": "¿Antigua o Ciudad de Guatemala?",
					"TEXT1": "¿Te preguntas cuál es el siguiente mejor paso cuando eliges Guatemala para su vida de expatriado?",
					"TEXT2": "Si bien todo depende de tus necesidades y preferencias, tenemos algunas ideas que podrían ayudarle a descubrir lo que es correcto para ti y tu familia.",
					"DATEPOST": "Publicado el 10 abril, 2023"
				},
				"CARD4": {
					"TITLE": "Caminando por las Calles de la Antigua Guatemala: Un Encuentro con la Belleza Histórica",
					"TEXT1": "La Antigua Guatemala, con su encanto colonial y su rica historia, es un destino que cautiva a los viajeros con su belleza y atmósfera única.",
					"TEXT2": "Sus calles empedradas y sus coloridas fachadas transportan a quienes las recorren a un pasado fascinante.",
					"DATEPOST": "Publicado el 26 de julio, 2023"
				},
				"CARD5": {
					"TITLE": "Los Chicken Buses: Un Viaje Colorido por el Paisaje Urbano de Antigua Guatemala",
					"TEXT1": "Como viajero apasionado, siempre busco descubrir experiencias únicas que me sumerjan en la cultura local.",
					"TEXT2": "Cuando escuché por primera vez sobre los legendarios \"chicken buses\" de Antigua Guatemala, supe que debía embarcarme en esta vibrante aventura.",
					"DATEPOST": "Publicado el 26 de julio, 2023"
				},
				"CARD6": {
					"TITLE": "5 Consejos para Dominar el Arte de la Búsqueda de Casas en 2024",
					"TEXT1": "¡Bienvenidos al futuro de la búsqueda de casas! En el siempre cambiante panorama inmobiliario, encontrar la casa de tus sueños o lograr una venta exitosa es toda una obra de arte. ",
					"TEXT2": "Con el 2024 sobre nosotros, exploremos cinco consejos dinámicos que transformarán tu experiencia de búsqueda de casa en un viaje emocionante, eficiente y gratificante.",
					"DATEPOST": "Publicado el 09 enero, 2024"
				},
				"CARD7": {
					"TITLE": "Explorando la Magia de Semana Santa en Antigua Guatemala",
					"TEXT1": "En las pintorescas calles empedradas de Antigua Guatemala, la Semana Santa cobra vida de una manera que cautiva los sentidos y alimenta el alma.",
					"TEXT2": "Esta antigua ciudad colonial se transforma en un escenario de devoción, tradición y color durante la celebración de la Semana Santa...",
					"DATEPOST": "Publicado el 23 febrero, 2024"
				},
				"CARD8": {
					"TITLE": "Tardes Mágicas: Disfrutando la Lluvia en Antigua Guatemala",
					"TEXT1": "Cada vez que llueve por la tarde, se produce un espectáculo de renovación y magia.",
					"TEXT2": "Esos son momentos en los que el cielo se viste de gris y las gotas de agua bailan sobre los adoquines coloniales, creando una escena que parece salida de un cuento de Gabriel García Márquez.",
					"DATEPOST": "Publicado el 11 abril, 2024"
				},
				"CARD9": {
					"TITLE": "Un Property Management con Impacto Social: El Compromiso de Luna Nueva",
					"TEXT1": "Hola, soy Jharan Marroquín, CEO de Luna Nueva, ",
					"TEXT2": " y hoy quiero compartir contigo algo que nos diferencia en el mundo de la administración de propiedades en Antigua Guatemala.  No somos solo una empresa que gestiona la renta a corto plazo de propiedades, somos un proyecto con propósito.",
					"DATEPOST": "Publicado el 26 septiembre, 2024"
				}
			},
			"detail": {
				"ARTICLE1": {
					"SECTION1": {
						"TITLE": "Ventajas de vivir en Antigua Guatemala: la seguridad es lo más importante",
						"TEXT1": "Ven a vivir a Guatemala (o, más bien, a Antigua Guatemala); ¡te encantará! Todo nuestro equipo vive aquí, y nos fascina. Te prometemos que te sentirás como en casa y que le contarás a todo el mundo lo bien que te lo estás pasando en el país de la eterna primavera",
						"TEXT2": "¿No es curioso cómo tendemos a postergar la idea de hacer algo increíble simplemente porque es nuevo? Según los estudios, estar cerca del límite de tu zona de confort durante un tiempo hará que empujes esa línea y amplíes tu zona de confort. Antigua Guatemala es el lugar ideal para empezar tu vida en el extranjero, y he aquí por qué: Antigua Guatemala es una ciudad pequeña que se establece perfectamente para tener todas las comodidades que necesitas y se diseñó para la hostelería.",
						"TEXT3": "Si bien Antigua Guatemala es el destino turístico más popular de Guatemala, también es el hogar de muchos extranjeros que han elegido vivir aquí, ¡y tú puedes ser el próximo! Algunos de los nuevos lugareños ya han viajado por el mundo y decidieron que Antigua Guatemala era su mejor opción. Vamos a explicar por qué en los próximos posts. Pero lo primero es lo primero. Echemos un vistazo a algunos de los conceptos erróneos más comunes acerca de por qué la gente cree que no es un lugar seguro para vivir. "
					},
					"SECTION2": {
						"TITLE": "Erupción del volcán en Antigua Guatemala",
						"TEXT1": "No hace falta decir que Antigua Guatemala está rodeada por tres volcanes, uno de los cuales está extremadamente activo. Activo, pero bajo control. Todos los antigueños prefieren que esté activo porque no sólo libera energía que mantiene la situación, sino que también ofrece un espectáculo cada noche que es muy entretenido de ver incluso si han vivido aquí durante mucho tiempo. Estamos seguros de que te apasionará verlo por la noche con una copa de vino en la mano.",
						"TEXT2": "Imagínate ir de excursión a Acatenago para ir a ver el Volcán de Fuego por la noche y pasar la noche acampando, o usar un 4x4 para llegar hasta allí. No pasa nada si no te apetece; siempre puedes contratar a un piloto privado para dar un paseo en helicóptero.  ¡Seguro que cada uno de ellos es toda una experiencia en sí mismo!  A propósito de esto, debemos añadir que siempre se recomienda sentido común. Reserva una excursión con un guía experimentado si desea subir al volcán en Antigua. Es fácil desorientarse o hacerse daño; recuerda que estás en plena naturaleza y que hay que respetar el aire libre, sobre todo cuando las condiciones cambian tan bruscamente."
					},
					"SECTION3": {
						"TITLE": "El tiempo en Antigua Guatemala a lo largo del año",
						"TEXT1": "La mejor época para visitar Guatemala es durante tu estación favorita, que varía de una persona a otra, pero ¿por qué no quedarte a vivir aquí y vivirlas todas? No dejes que otras personas te digan que visitar Antigua de noviembre a marzo (o durante la estación seca) es la mejor y única opción. Si vienes durante la estación lluviosa, te sorprenderá lo bonito y floreciente que es todo. Cada estación tiene sus ventajas e inconvenientes, pero todo depende de ti y de cómo quieras vivir tu experiencia",
						"TEXT2": "A diferencia de los hemisferios norte o sur, Centroamérica tiene estaciones divididas en dos grandes grupos: la estación seca y la estación lluviosa.  La estación seca incluye festividades importantes como Navidad, Año Nuevo, Cuaresma y Semana Santa, mientras que la estación húmeda incluye el Día de la Madre, el Día del Padre y el Día de la Independencia. Cada una de ellas tiene su encanto. ",
						"TEXT3": "Volviendo a la pregunta original, ¿es seguro estar en Antigua Guatemala durante la temporada de lluvias? La respuesta es sí. Aun así, hay que evitar salir a la calle cuando llueve con fuerza, ya que las calles pueden inundarse rápidamente, sobre todo si lleva lloviendo mucho tiempo. Por desdicha, los atascos de alcantarillas y los deslizamientos siguen existiendo, pero las autoridades se esfuerzan por arreglarlos con rapidez para que no causen grandes demoras en el tráfico."
					},
					"SECTION4": {
						"TITLE": "¿Se puede comer fuera en Antigua?",
						"TEXT1": "¿Tienes el estómago delicado? No te preocupes. Hay muchas opciones entre las que elegir, así que es seguro comer fuera en Antigua Guatemala. Aprenderás a adaptarte y a seguir algunas normas básicas, como no beber agua del grifo, lavarte las manos antes de comer, evitar comer en puestos callejeros y restaurantes poco concurridos, evitar el repollo y la lechuga, y ser consciente de tus propias alergias y sensibilidades alimentarias. No debería haber mayores preocupaciones por comer localmente mientras se vive en Guatemala.",
						"TEXT2": "Algunos de estos consejos son de sentido común, pero permítenos explicarte por qué debe vigilar las verduras de hoja que consume durante su visita y evitar los restaurantes poco frecuentados. La sencilla razón es que las plantaciones de tierra están más expuestas a la E. coli, y el enjuague de las verduras es insuficiente para eliminar las bacterias. Por otra parte, los restaurantes poco frecuentados implican una menor rotación del inventario y más posibilidades de que la comida esté rancia o estropeada. Además, evita llevar comida para llevar porque lo más probable es que la tengas fuera de la refrigeradora durante un tiempo, y eso puede estropear fácilmente los alimento"
					},
					"SECTION5": {
						"TITLE": "Pasear por Antigua Guatemala",
						"TEXT1": "Preocuparte por tu seguridad personal es muy importante, y forma parte de nuestros instintos primitivos. Pero seamos realistas: existe delincuencia en todas partes, incluso en los países más desarrollados. Antigua Guatemala no es la excepción, pero por el lado bueno, la violencia está bajo control, y la pequeña delincuencia es lo único sensible. Nada que el ser cuidadosos no pueda evitar.",
						"TEXT2": "Entonces, ¿cómo optimizar su nivel de seguridad mientras vives aquí? Muy sencillo: evita las calles oscuras y solitarias por la noche, presta ayuda sólo si tu seguridad no está en peligro, no vayas a fiestas clandestinas (que son 100% ilegales debido a la hora de límite), no intentes comprar sustancias ilegales, evita subir a los buses colectivos que van fuera de Sacatequez (por ejemplo, el colectivo que va a Ciudad de Guatemala), mantente atento mientras haces transacciones con tarjetas de crédito o débito, para que no clonen la tarjeta o hagan una doble transacción, y lo más importante, prepara un plan de contingencia en caso de que pasen cosas, para que sepas qué hacer después."
					},
					"SECTION6": {
						"TITLE": "Conclusiones",
						"TEXT1": "Reconsidera de dónde viene la preocupación e investiga cómo encontrar una solución para disfrutar de tu nueva vida en Antigua Guatemala. Suscríbete para recibir más consejos sobre tu viaje a Antigua y compártelos con tus amigos para que ellos también puedan descubrir las maravillas del corazón del mundo maya."
					},
					"DATEPOST": "Publicado el 10 abril, 2023"
				},
				"ARTICLE2": {
					"SECTION1": {
						"TITLE": "Entendiendo las particularidades de Antigua Guatemala: lo que nos hace únicos",
						"TEXT1": "Para nosotros, la era mexicana ha terminado, y ahora debemos descubrir lo que hay más allá. Como dice el refrán, \"ya he pasado por eso\" lo resume bastante bien.  Con la era de los nómadas digitales, se han abierto muchas oportunidades, ¡y el mundo es tuyo! Ahora que ya está claro, ¿por qué no le das una oportunidad a Guatemala? ¿Has oído hablar de una pequeña ciudad llamada Antigua Guatemala? Tenemos todo lo que necesitas para vivir una vida cómoda, similar a la que tienes en casa. Algunos pensamos que reúne todas las comodidades de una gran ciudad con el lujo de llevar una vida relajada que sólo los pueblos pequeños pueden ofrecer.",
						"TEXT2": "Cuéntanos en la sección de comentarios cuáles son tus gustos e intereses. Lo más probable es que aquí en Antigua Guatemala también los tengamos. Estamos orgullosos de vivir en una ciudad multicultural que da cabida a una gran variedad de estilos de vida, desde el ultraminimalista al ultraopulento, desde el orientado a la carrera profesional al orientado a la familia, por no hablar de las oportunidades espirituales que ofrece un lugar con tantas culturas. Así que sumerjámonos primero en las particularidades de esta joya."
					},
					"SECTION2": {
						"TITLE": "Conociendo la cultura local de los antigueños",
						"TEXT1": "Este es un tema tan amplio, así que vamos a dividirlo en dos grandes categorías que nos ayudarán a ejemplificar lo palpable. La primera categoría sería la influencia guatemalteca, y la segunda la influencia extranjera. Y aunque las estudiaremos por separado, para entenderlas mejor, debemos aclarar que ambas coexisten y se influencian mutuamente, lo cual representa la belleza de este pueblo y lo hace tan auténtico y diferente a la vez.",
						"TEXT2": "Así que empecemos por la parte guatemalteca, que es realmente fascinante descubrir cómo tantas generaciones y etnias diferentes contribuyen a la identidad nacional. Los ancianos aún mantienen los métodos tradicionales de socialización que contribuyen a fomentar una cultura hospitalaria y acogedora. Las generaciones más jóvenes quieren aprender más sobre el mundo y tienen energía para probar nuevas formas de vida, como los estilos holísticos y artísticos. También quieren que su trabajo sea más internacional. Pero, ¿qué significa esto para usted como posible expatriado? Significa que encontrarás buenos colegas en Guatemala y potencialmente podrías asociarte con ellos para hacer cosas increíbles en línea.",
						"TEXT3": "Hablemos ahora de la influencia extranjera. Década tras década, los extranjeros han llegado y se han establecido en Guatemala, dándonos regalos asombrosos como el Queso Chancol, que ahora es bien reconocido y disfrutado por muchos guatemaltecos. Y si revisáramos las contribuciones en una escala más pequeña y más local a Antigua Guatemala, podríamos ver mejoras en muchas industrias, incluyendo el sector educativo, que ha abierto sus puertas para tener programas de nivel internacional. Esto le facilita la vida a una familia que busca la transición a una vida de ex-patriado y para los locales poder perseguir una carrera en el extranjero. Otro cambio importante es el crecimiento de la industria hotelera, que pone a Guatemala en el mapa (por su excelente servicio bilingüe) y ayuda a mantener la economía en movimiento. ¿Y la comida? ¿Hay alguna influencia en la gastronomía? Vamos a sumergirnos en ello en la siguiente sección."
					},
					"SECTION3": {
						"TITLE": "El recorrido del comensal en Antigua Guatemala",
						"TEXT1": "Antigua Guatemala solía ser un pueblo aislado de la vida urbana de los alrededores y de la ciudad. Como resultado, desarrolló sus propias tradiciones culinarias. Había muchos negocios de dulces en esta zona, y su amplia selección de delicias era suficiente para tentar a cualquiera con antojo de golosinas. Aunque las únicas opciones gastronómicas eran los dulces y algunos cuantos otros platos tradicionales, la llegada de turistas e inversores de otros países aumentó enormemente las opciones.",
						"TEXT2": "Antigua Guatemala ha experimentado una transformación para atender a una mayor variedad de clientes, incluidos los que están interesados en probar la cocina tradicional guatemalteca, así como los que sienten curiosidad por otros tipos de cocina de todo el mundo. Apostamos a que tu experiencia global en esta tierra mejorará como fruto de ello, y se sentirá como en casa en mucho menos tiempo.",
						"TEXT3": "Algunos de los restaurantes locales se esfuerzan al máximo por ofrecer un gran servicio, creando entornos únicos que ofrecen experiencias memorables y ambientes que no discriminan entre grupos de diferentes edades, lo que lo convierte en un lugar ideal para toda la familia. Por este motivo, creemos que los restaurantes se superan a sí mismos a lo largo del tiempo y siguen introduciendo un componente de novedad que le mantendrá enganchado y hará que vuelvas una y otra vez. Por ello, la dieta ahora es más diversificada, al igual que el entorno dónde puedas llevar a tus amigos y familiares."
					},
					"SECTION4": {
						"TITLE": "Lugares de interés y ambiente",
						"TEXT1": "Sí, Antigua tiene mucha historia, pero seamos sinceros: eso no es lo más destacado para mucha gente que decide venir a vivir aquí, a menos que seas un entusiasta de la historia, la arqueología o la sociología. Pero, ¿sabes qué es lo mejor de Antigua? Ver sitios. ¿Qué puede haber más divertido que salir a explorar lo que ofrece esta pintoresca ciudad? Lo creas o no, puedes pasarte un año entero explorando lugares y seguir encontrando sitios nuevos a los que ir. O incluso emocionarte con los nuevos viveros que se inauguran y llevan una planta nueva que no has visto en ningún otro sitio o esa nueva tiendecita bonita que abre y trae un montón de importaciones nostálgicas que te hacen sentir bendecido por estar aquí y tener un trocito de tu madre patria al mismo tiempo.",
						"TEXT2": "Y por si fuera poco, cada vez que visites un lugar nuevo, encontrarás un olor o un sonido particular que se convertirá en algo muy representativo de él, y lo recordarás cada vez que vayas. Al igual que las bandas locales, es algo que enamora. Todos son tan talentosos y únicos que fácilmente puedes terminar planeando tu semana alrededor de sus horarios de presentación en restaurantes. De eso hablaremos en otro post, junto con otras razones para elegir Antigua Guatemala sobre cualquier otra parte del mundo. Pulsa el botón de suscripción para recibir notificaciones, y no dudes en compartir esta información con tus amigos."
					},
					"DATEPOST": "Publicado el 10 abril, 2023"
				},
				"ARTICLE3": {
					"SECTION1": {
						"TITLE": "¿Antigua o Ciudad de Guatemala?",
						"TEXT1": "¿Te preguntas cuál es el siguiente mejor paso cuando eliges Guatemala para su vida de expatriado? Si bien todo depende de tus necesidades y preferencias, tenemos algunas ideas que podrían ayudarle a descubrir lo que es correcto para ti y tu familia. Ve por tus snacks y té porque estamos a punto de sumergirnos en la parte jugosa donde te decimos lo que ningún otro sitio o persona te dirá en profundidad. Hablaremos de todo, desde la movilidad hasta dónde encontrar esos momentos que tanto te gustan."
					},
					"SECTION2": {
						"TITLE": "Desplazamiento por la zona",
						"TEXT1": "La vida en el pueblo y la vida en la ciudad tienen una gran diferencia en estilos de vida, empezando por la forma en que te transportas a otros lugares. Mientras que las grandes ciudades como Ciudad de Guatemala utilizan los coches y las motos como medio de transporte habitual, Antigua Guatemala permite otras experiencias divertidas como dar un paseo en tuc-tuc o simplemente caminar. Con esto no estamos diciendo que Antigua no tenga los medios para manejar vehículos. Ambos lugares tienen facilidades para circular y también para estacionarse. Además, ambos tienen transporte colectivo como los buses, pero no es recomendable tomarlos para ir a la ciudad ya que suelen ser muy agitados y tienen mala reputación. Por otro lado, los autobuses en Antigua Guatemala, son muy seguros de usar y fáciles de tomar desde la terminal o de camino al lugar al que te dirigías.",
						"TEXT2": "Una vez que conozcas a algunos conductores de tuc-tuc en los que confíes, puedes pedirles sus números de teléfono y ellos serán como tus taxistas privados. Sus tarifas pueden variar según el número de personas que te acompañen o la distancia que recorras, pero lo más probable es que oscilen entre Q15 y Q30. Los viajes en autobús van desde Q3 a Q7 dentro de Sacatepéquez, pero si quieres ir más allá, podría costar alrededor de Q15-Q20 por autobús."
					},
					"SECTION3": {
						"TITLE": "Distancias de un lugar a otro",
						"TEXT1": "Ciudad de Guatemala es muy conocida por su tráfico en horas pico, que actualmente se está descontrolando. Mucha gente opta por salir a las 4 a.m. de la mañana para estacionarse afuera de sus trabajos o universidades y tomar una siesta en el lugar sólo para ahorrar tiempo y gasolina en su viaje, pero esa no es la misma historia para Antigua Guatemala. Una de las maravillas de esta encantadora ciudad es que realmente muestra cómo debería ser una ciudad de 15 minutos. Estés donde estés, puedes caminar unas pocas cuadras y encontrar una farmacia (o droguería, como quieras llamarla), bancos y lugares donde comer o hacer pequeñas compras para tu uso diario. La gente de esta zona suele trabajar en línea, por lo que no necesitan desplazarse, y las calles tienen un tráfico fluido excepto en días festivos como Semana Santa o la Fiesta de las Flores."
					},
					"SECTION4": {
						"TITLE": "Comparación de estilos de vida",
						"TEXT1": "La vida en la ciudad es para los que quieren estar conectados con el trabajo corporativo y darse el capricho de disfrutar de restaurantes de lujo y tiendas de marca junto con colegios de prestigio. Pero no tenemos por qué renunciar a todo eso porque sí. Podemos tener un poco de todo eso con muchas actividades de ocio que nos ayudan a descomprimirnos sin agotar nuestro cuerpo. Antigua Guatemala es un pequeño oasis en el desierto. Esta tierra tiene mucho que ofrecer, desde actividades para toda la familia hasta excursiones extremas que nunca aburren.",
						"TEXT2": "Sabemos que es difícil pensar que un lugar pueda tenerlo todo, pero Antigua está ahí para romper ese estigma y demostrarte que hay otra manera. Puedes seguir disfrutando de ir a restaurantes de lujo estando mucho más cerca de tu casa, lo que significa que no hay necesidad de tener un conductor designado y todos tus invitados pueden tomar una copita. O también significa que puedes cenar en un sitio y tomar el postre en otro, a sólo unos pasos de donde estbas.",
						"TEXT3": "Y además, si le interesa la educación de calidad de sus hijos, creemos que éste es el lugar adecuado para ti. Podrás enseñar a tus hijos a llevar un ritmo de vida relajado y conectado con la naturaleza y la agricultura. Algunas escuelas locales tienen métodos de enseñanza alternativos y enseñarán a tus hijos las cosas valiosas que ofrece la Madre Tierra. Por mencionar sólo algunos ejemplos, hay una escuela que está anexa a una plantación de café y ha construido su propio mariposario. Otras escuelas más pequeñas prefieren tener clases reducidas y prestar más atención a cada niño, limitando sus grupos a 10 niños por profesor."
					},
					"SECTION5": {
						"TITLE": "Ecoturismo",
						"TEXT1": "La vida en la ciudad puede ser estresante, pero siempre se puede optar por un estilo de vida alternativo y más sano. En este sentido, la capital no tiene tantas opciones como Antigua Guatemala. Aquí hay muchos senderos naturales que se pueden visitar. Algunos de ellos son gratuitos por estar fuera del camino, y otros son pagados pero valen la pena. ¿Quién imaginaría que se puede tener una selva tropical en medio de una ciudad pintoresca? Sí. Este es el país de las oportunidades, y no se queda ahí. No hace falta ser un atleta de alto impacto para disfrutar de las vistas. Puedes optar fácilmente por senderos fáciles como el emblemático Cerro de la Cruz, sobre todo ahora que ha sido remodelado y diseñado para disfrutar de conciertos al aire libre."
					},
					"SECTION6": {
						"TITLE": "Acogida de mascotas",
						"TEXT1": "Y por último, pero no por ello menos importante, hablemos del bienestar de nuestros amigos peludos. No es ninguna broma que los perros son los bebés de hoy en día, y cada vez somos más conscientes de su necesidad de socialización. Tanto la ciudad como Antigua ofrecen lugares de paseo aptos para mascotas, pero creemos que Antigua se ha acercado mucho más a convertirse en el paraíso de los perros. Ya no es habitual preguntar si los perros son bienvenidos en algún sitio, porque todos son bienvenidos siempre que no haya un cartel que indique lo contrario. Estas señales suelen encontrarse sólo en supermercados, hospitales, monumentos y bancos, pero es comprensible. Desgraciadamente, no todos los dueños son tan concienzudos con el aseo y adiestramiento de sus mascotas, por lo que no deberían permitirse en espacios públicos donde puedan suponer una amenaza para la salud de las personas o perturbar un ambiente formal. Pero aparte de estos 4 tipos de lugares, su amigo de 4 patas será bienvenido en todas partes, y disfrutará viviendo de otra manera, especialmente cuando aproveche los senderos que sólo Antigua puede ofrecer."
					},
					"DATEPOST": "Publicado el 10 abril, 2023"
				},
				"ARTICLE4": {
					"SECTION1": {
						"TITLE": "Caminando por las Calles de la Antigua Guatemala: Un Encuentro con la Belleza Histórica",
						"TEXT1": "La Antigua Guatemala, con su encanto colonial y su rica historia, es un destino que cautiva a los viajeros con su belleza y atmósfera única. Sus calles empedradas y sus coloridas fachadas transportan a quienes las recorren a un pasado fascinante."
					},
					"SECTION2": {
						"TEXT1": "Al adentrarse en las estrechas calles de la Antigua, el ambiente vibrante y lleno de energía envuelve a los visitantes. Los edificios coloniales, con sus balcones de hierro forjado y sus fachadas coloridas, ofrecen un escenario perfecto para el viajero curioso. Cada esquina revela una nueva historia, una conexión con el pasado que se mantiene viva en cada piedra."
					},
					"SECTION3": {
						"TEXT1": "Uno de los aspectos más cautivadores de caminar por las calles de la Antigua Guatemala es la presencia de iglesias y conventos históricos. Estas hermosas estructuras religiosas, como la Catedral de Santiago y la Iglesia de La Merced, son testigos silenciosos de siglos de devoción y fe. Sus interiores barrocos, adornados con retablos tallados a mano y retazos de arte sacro, son un deleite visual para cualquier amante de la historia y la arquitectura."
					},
					"SECTION4": {
						"TEXT1": "Pero la Antigua no solo es una ciudad de iglesias y conventos. Sus calles también albergan plazas encantadoras, donde los visitantes pueden sentarse bajo la sombra de los árboles y disfrutar de la atmósfera relajada. La Plaza Mayor, rodeada de majestuosos edificios coloniales y la imponente fachada del Palacio de los Capitanes Generales, es el corazón de la ciudad y un lugar ideal para contemplar el bullicio cotidiano."
					},
					"SECTION5": {
						"TEXT1": "A medida que uno se sumerge en las calles empedradas de la Antigua, descubre una gran cantidad de tiendas de artesanía, cafés acogedores y restaurantes que ofrecen delicias gastronómicas. Desde textiles tradicionales hasta joyas hechas a mano, el arte y la artesanía local son una parte integral de la experiencia de caminar por la ciudad."
					},
					"SECTION6": {
						"TEXT1": "Pero más allá de su belleza tangible, caminar por las calles de la Antigua Guatemala también implica sumergirse en su espíritu cultural. Los festivales coloridos, la música en vivo y la hospitalidad de su gente crean un ambiente acogedor y festivo. Los lugareños se enorgullecen de compartir su herencia cultural y están dispuestos a contar historias que hacen eco en el alma de los visitantes.",
						"TEXT2": "En resumen, la experiencia de caminar por las calles de la Antigua Guatemala es un viaje en el tiempo que combina historia, arquitectura, arte y cultura. Es un lugar donde el pasado se entrelaza con el presente, y cada paso es una oportunidad para descubrir una nueva historia esperando ser contada."
					},
					"DATEPOST": "Publicado el 26 de julio, 2023"
				},
				"ARTICLE5": {
					"DATEPOST": "Publicado el 26 de julio, 2023",
					"SECTION1": {
						"TITLE": "Los Chicken Buses: Un Viaje Colorido por el Paisaje Urbano de Antigua Guatemala",
						"TEXT1": "Como viajero apasionado, siempre busco descubrir experiencias únicas que me sumerjan en la cultura local. Cuando escuché por primera vez sobre los legendarios \"chicken buses\" de Antigua Guatemala, supe que debía embarcarme en esta vibrante aventura. Sin saberlo, subirme a uno de estos vehículos decorados de manera llamativa no solo sería un medio de transporte, sino también un emocionante viaje por el corazón y el alma de la ciudad."
					},
					"SECTION2": {
						"TEXT1": "Mientras me acercaba a la bulliciosa estación de autobuses, una sinfonía de bocinas y conversaciones animadas llenaba el aire. El panorama ante mí era un festín para los ojos: una flota de antiguos autobuses escolares estadounidenses, transformados en deslumbrantes obras de arte. Cada autobús lucía su propio caleidoscopio de colores, patrones ornamentados y diseños caprichosos, mostrando con orgullo la creatividad e ingenio de los artesanos locales."
					},
					"SECTION3": {
						"TEXT1": "Con una sensación de anticipación, subí a uno de estos prodigios rodantes. El interior me recibió con un ambiente animado: música enérgica sonando de fondo, risas resonando en el aire y una cálida camaradería entre pasajeros de diferentes ámbitos de la vida. El propio autobús parecía cobrar vida, palpitando con energía y carácter que reflejaban a la perfección el espíritu vibrante de Antigua."
					},
					"SECTION4": {
						"TEXT1": "Mientras recorríamos las calles de la ciudad, quedé maravillado por la habilidad del conductor para maniobrar con destreza, abriéndose paso elegantemente por espacios estrechos y fusionándose sin problemas con el tapiz colorido del tráfico. El viaje en sí fue una aventura llena de adrenalina, mientras nos abríamos paso por el laberinto de calles empedradas, cada giro y vuelta ofreciendo una nueva sorpresa. Era como si el autobús tuviera personalidad propia, guiándonos a través de un cautivador paisaje urbano."
					},
					"SECTION5": {
						"TEXT1": "Pero más allá del emocionante paseo, lo que realmente me impactó fue el sentido de comunidad dentro del autobús. Personas de todas las edades, procedencias e historias se mezclaban, sus conversaciones se fusionaban en una sinfonía armoniosa de experiencias compartidas. Los desconocidos se convertían en amigos, la risa se convertía en un lenguaje común y el autobús se transformaba en un crisol móvil de culturas y conexiones.",
						"TEXT2": "A medida que hacíamos paradas en diferentes lugares para recoger y dejar pasajeros, el autobús se convertía en un microcosmos de la vida diaria de Antigua. Observé a vendedores ambulantes subiendo al autobús, sus coloridos productos apilados en grandes cantidades. Familias locales con niños riendo y saludando a amigos en el camino. Incluso el ocasional cacareo de gallinas, fiel al apodo del autobús, añadía un toque de autenticidad rural en medio del caos urbano.",
						"TEXT3": "Finalmente, mi viaje en el chicken bus llegó a su fin. Al bajar del autobús."
					}
				},
				"ARTICLE6": {
					"DATEPOST": "Publicado el 09 enero, 2024",
					"SECTION1": {
						"TITLE": "5 Consejos para Dominar el Arte de la Búsqueda de Casas en 2024",
						"TEXT1": "¡Bienvenidos al futuro de la búsqueda de casas! En el siempre cambiante panorama inmobiliario, encontrar la casa de tus sueños o lograr una venta exitosa es toda una obra de arte. Con el 2024 sobre nosotros, exploremos cinco consejos dinámicos que transformarán tu experiencia de búsqueda de casa en un viaje emocionante, eficiente y gratificante."
					},
					"SECTION2": {
						"TITLE": "Consejo 1: Acepta las Visitas Virtuales en Realidad Virtual",
						"TEXT1": "En la era del avance tecnológico, dile adiós a las tradicionales jornadas de puertas abiertas y dale la bienvenida a las visitas virtuales en realidad virtual. Sumérgete en una propiedad desde la comodidad de tu sofá, explorando cada rincón sin estar físicamente presente. Descubre cómo este enfoque innovador facilita la búsqueda de casa."
					},
					"SECTION3": {
						"TITLE": "Consejo 2: Aprovecha la Asistencia Personalizada de la Inteligencia Artificial",
						"TEXT1": "¡Saluda a tu asistente virtual inmobiliario! Los algoritmos impulsados por inteligencia artificial personalizan tu búsqueda de propiedades, presentando opciones adaptadas a tus preferencias. Desde barrios específicos hasta comodidades deseadas, observa cómo la inteligencia artificial simplifica tu búsqueda del hogar perfecto."
					},
					"SECTION4": {
						"TITLE": "Consejo 3: Las Características Ecológicas están de Moda",
						"TEXT1": "El movimiento ecoconsciente está moldeando el panorama inmobiliario. Descubre cómo las propiedades equipadas con características sostenibles no solo contribuyen a un planeta más verde, sino que también mejoran tu estilo de vida y el valor de tu hogar. Explora cómo los paneles solares, electrodomésticos eficientes en energía y diseños ecológicos elevan tu lista de verificación en la búsqueda de casa."
					},
					"SECTION5": {
						"TITLE": "Consejo 4: Enfatiza la Comunidad y el Estilo de Vida",
						"TEXT1": "No se trata solo de la casa; se trata del estilo de vida que ofrece. Sumérgete en la importancia de elegir una propiedad que resuene con la vibra de la comunidad que deseas. Explora cómo las comodidades, la cultura del vecindario y las actividades locales desempeñan un papel fundamental en tus decisiones de búsqueda de casa."
					},
					"SECTION6": {
						"TITLE": "Consejo 5: Estrategias de Negociación para Acuerdos Exitosos",
						"TEXT1": "Revela el arte de la negociación. Comprende las tendencias del mercado, las estrategias de precios y las tácticas para cerrar el trato. Aprende cómo navegar por las guerras de ofertas, aprovechar tu posición y asegurar el mejor resultado posible, ya sea comprando o vendiendo.",
						"TEXT2": "Conclusión:",
						"TEXT3": "El 2024 trae una gran cantidad de oportunidades e innovaciones en el ámbito inmobiliario. Estos cinco consejos son tu arsenal para conquistar el paisaje de búsqueda de casa en el nuevo año. Acepta la evolución, redefine tu enfoque y emprende un viaje de búsqueda de casa que sea a la vez placentero y exitoso.",
						"TEXT4": "Recuerda, ¡Luna Nueva Marketplace es tu puerta de entrada a una experiencia inmobiliaria fluida en 2024 y más allá!"
					}
				},
				"ARTICLE7": {
					"DATEPOST": "Publicado el 23 febrero, 2024",
					"SECTION1": {
						"TITLE": "Explorando la Magia de Semana Santa en Antigua Guatemala",
						"TEXT1": "En las pintorescas calles empedradas de Antigua Guatemala, la Semana Santa cobra vida de una manera que cautiva los sentidos y alimenta el alma. Esta antigua ciudad colonial se transforma en un escenario de devoción, tradición y color durante la celebración de la Semana Santa, convirtiéndose en un destino obligatorio para los viajeros que buscan experimentar la magia y la mística de esta festividad única."
					},
					"SECTION2": {
						"TEXT1": "Las procesiones, que son el corazón de la Semana Santa en Antigua Guatemala, son un espectáculo impresionante que deja una huella imborrable en la memoria de quienes tienen el privilegio de presenciarlas. Desde el Domingo de Ramos hasta el Domingo de Resurrección, las calles se llenan con el sonido de los tambores, el aroma de las flores y la ferviente devoción de los guatemaltecos que participan en estas manifestaciones de fe."
					},
					"SECTION3": {
						"TEXT1": "Cada procesión está acompañada por impresionantes andas que llevan imágenes religiosas ricamente decoradas, mientras los fieles las llevan en hombros por las calles adornadas con alfombras de colores vibrantes. Estas alfombras, elaboradas con meticulosidad y dedicación, son una expresión de la profunda fe y creatividad de la comunidad, y representan una parte integral de la experiencia de Semana Santa en Antigua Guatemala."
					},
					"SECTION4": {
						"TEXT1": "Pero más allá de la belleza visual de las procesiones y las alfombras, la Semana Santa en Antigua Guatemala es una celebración que profundiza en la rica tradición cultural y religiosa del país. Es una oportunidad para conectarse con la historia y la espiritualidad de Guatemala, y para experimentar de cerca la devoción y el fervor de su gente."
					},
					"SECTION5": {
						"TEXT1": "Durante esta semana sagrada, los visitantes tienen la oportunidad de sumergirse en la atmósfera única de Antigua Guatemala, explorando sus encantadoras iglesias coloniales, saboreando la deliciosa gastronomía local y participando en las numerosas actividades culturales que se organizan en honor a la Semana Santa."
					},
					"SECTION6": {
						"TEXT1": "En resumen, la Semana Santa en Antigua Guatemala es mucho más que una celebración religiosa; es una experiencia transformadora que combina la espiritualidad, la tradición y la cultura en un ambiente de belleza y solemnidad. Es un recordatorio de la importancia de la fe y la comunidad, y una invitación a todos los viajeros a sumergirse en la magia y la mística de esta festividad inolvidable."
					}
				},
				"ARTICLE8": {
					"DATEPOST": "Publicado el 11 abril, 2024",
					"SECTION1": {
						"TITLE": "Tardes Mágicas: Disfrutando la Lluvia en Antigua Guatemala",
						"TEXT1": "Cada vez que llueve por la tarde, se produce un espectáculo de renovación y magia. Esos son momentos en los que el cielo se viste de gris y las gotas de agua bailan sobre los adoquines coloniales, creando una escena que parece salida de un cuento de Gabriel García Márquez."
					},
					"SECTION2": {
						"TEXT1": "El año anterior, las lluvias fueron inusuales y desafiaron los patrones climáticos usuales, sorprendiendo a todos. A pesar de esto, en lugar de ser motivo de preocupación, esta variabilidad climática nos hizo recordar la importancia de valorar cada momento, incluso cuando la naturaleza elige seguir su propio curso."
					},
					"SECTION3": {
						"TEXT1": "En Antigua Guatemala, la lluvia es más que un fenómeno meteorológico; las montañas y la vegetación son tan importantes como las ruinas y las calles empedradas en su esencia. Es un regalo para la tierra sedienta, una bendición para que las plantas y árboles puedan florecer y crecer en todo su esplendor con estas aguas. La vegetación se renueva, los colores adquieren más intensidad y el aire se llena de frescura, formando un paisaje que embruja los sentidos. "
					},
					"SECTION4": {
						"TEXT1": "La lluvia en Antigua Guatemala tiene un encanto especial cuando se observa desde la comodidad de una ventana, aunque más allá de su importancia para el ecosistema local. El sonido de las gotas golpeando los tejados, el olor a tierra mojada en el aire y la vista de las hojas de los árboles meciéndose suavemente con el viento invitan a reflexionar y contemplar. "
					},
					"SECTION5": {
						"TEXT1": "Quienes viven o visitan esta encantadora ciudad tienen la suerte de que las tardes lluviosas les brinden la oportunidad de desconectar del ajetreo diario y sumergirse en la tranquilidad que solo puede ofrecer la naturaleza."
					}
				},
				"ARTICLE9": {
					"DATEPOST": "Publicado el 26 septiembre, 2024",
					"SECTION1": {
						"TITLE": "Un Property Management con Impacto Social: El Compromiso de Luna Nueva",
						"TEXT1": "Hola, soy Jharan Marroquín, CEO de Luna Nueva, y hoy quiero compartir contigo algo que nos diferencia en el mundo de la administración de propiedades en Antigua Guatemala. No somos solo una empresa que gestiona la renta a corto plazo de propiedades, somos un proyecto con propósito. En Luna Nueva, hemos creado un modelo que no solo busca rentabilidad, sino también generar un impacto social tangible en nuestra comunidad."
					},
					"SECTION2": {
						"TITLE": "¿Qué es Property Management en Luna Nueva?",
						"TEXT1": "Cuando hablamos de property management, estamos hablando de un servicio integral, de un círculo de 360 grados. Desde la limpieza, mantenimiento, atención al cliente, hasta las ventas y postventa, nos encargamos de cada detalle para que los propietarios no tengan que preocuparse por nada. Rentamos propiedades por noche, semana o mes, dependiendo de las necesidades de nuestros clientes. Nuestro enfoque está 100% centrado en Antigua Guatemala y sus alrededores, un lugar donde la cultura, la historia y las tradiciones se entrelazan. Pero, lo que realmente nos hace únicos en este sector no es solo el servicio. En Luna Nueva hemos encontrado una manera de integrar un fuerte impacto social en nuestra gestión. Cada propiedad bajo nuestra administración, hoy alrededor de 20, no solo es un lugar para hospedar a viajeros, sino una oportunidad para aportar a la comunidad y a su desarrollo."
					},
					"SECTION3": {
						"TITLE": "Nuestro Impacto Social: Un Modelo con Tres Pilares",
						"DOTS1TITLE": "1. Educación Gratuita para Nuestra Comunidad",
						"DOTS1": "Creemos en la educación como la base para el crecimiento. Por eso, en Luna Nueva ofrecemos cursos gratuitos en línea a todo nuestro equipo, sus familiares y amigos. Actualmente impartimos cursos de inglés, y próximamente estaremos lanzando más opciones que ayudarán al desarrollo personal y profesional de nuestra comunidad. No solo trabajamos con los mejores, sino que también invertimos en su crecimiento.",
						"DOTS2TITLE": "2. Capacitación para Ofrecer Desayunos Tradicionales",
						"DOTS2": "Antigua Guatemala tiene una riqueza cultural que merece ser compartida. Por eso, hemos capacitado a nuestro equipo de limpieza en algunas de nuestras propiedades para que puedan ofrecer desayunos tradicionales guatemaltecos a nuestros huéspedes. En estas propiedades, los viajeros disfrutan de una experiencia completa al saborear recetas locales, conectándose con la cultura guatemalteca desde la primera comida del día. Esta capacitación no solo eleva la experiencia del huésped, sino que también brinda nuevas habilidades y oportunidades a nuestro equipo.",
						"DOTS3TITLE": "3. Apoyo al Arte Local: Espacios para Artistas",
						"DOTS3": "En nuestras propiedades, hemos designado un espacio especial para exhibir obras de artistas locales. Creemos que el arte es una forma poderosa de conectar a las personas con la cultura, y queremos darles a los creadores guatemaltecos la visibilidad que merecen. Al integrar piezas de arte en nuestros espacios, no solo decoramos las propiedades, sino que también brindamos un escenario para que los artistas locales compartan su talento con el mundo."
					},
					"SECTION4": {
						"TITLE": "Más que Property Management: Somos una Comunidad",
						"TEXT1": "En Luna Nueva, entendemos que la administración de propiedades no se trata solo de números y reservas, sino de las personas detrás de cada propiedad, cada huésped, y cada miembro de nuestro equipo. Este enfoque nos ha permitido crecer de manera sostenida y generar un impacto positivo en nuestra querida Antigua Guatemala. Si eres propietario y estás buscando un servicio de renta a corto plazo que no sea el típico, te invito a ser parte de Luna Nueva. Te prometo que no solo cuidaremos de tu propiedad como si fuera nuestra, sino que también contribuirás a algo más grande: el desarrollo de nuestra comunidad, el apoyo al arte local, y el empoderamiento de personas que trabajan cada día para ofrecer lo mejor de sí mismas. Gracias por ser parte de esta historia.",
						"TEXT2": "Gracias por ser parte de esta historia",
						"TEXT3": "- Jharan"
					}
				},
				"TEXTBACK": "Volver al blog",
				"PUBLICATION": "Publicado el 10 abril, 2023",
				"TOOLTIPFB": "Compartir en Facebook",
				"TOOLTIPWA": "Compartir en WhatsApp",
				"TOOLTIPCP": "Copiar Link",
				"MEDIAMSG": "Compartir"
			}
		}
	}
}
