import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, NgOptimizedImage, } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MaterialModule } from "../../modules/material.module";
import { LnFormFieldInputComponent } from "./ln-form-field-input/ln-form-field-input.component";
import { LnFormFieldSelectComponent } from "./ln-form-field-select/ln-form-field-select.component";
import { LnCheckboxComponent } from "./ln-checkbox/ln-checkbox.component";
import { LnButtonLoadingComponent } from './ln-button-loading/ln-button-loading.component';
import { LnButtonComponent } from './ln-button/ln-button.component';
import { LnFormFieldPasswordComponent } from './ln-form-field-password/ln-form-field-password.component';
import { LnDialogInfoComponent } from './ln-dialog-info/ln-dialog-info.component';
import { LnBackButtonComponent } from './ln-back-button/ln-back-button.component';
import { LnPriceSliderComponent } from './ln-price-slider/ln-price-slider.component';
import { LnCardMapsComponent } from './ln-card-maps/ln-card-maps.component';
import { LnCardSkeletonComponent } from "./ln-card-skeleton/ln-card-skeleton.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { LnPaginatorComponent } from './ln-paginator/ln-paginator.component';
import { RouterLink } from "@angular/router";
import { LnCardServiceComponent } from './ln-card-service/ln-card-service.component';
import { LnFormFieldSelectMultipleComponent } from './ln-form-field-select-multiple/ln-form-field-select-multiple.component';
import { LnFormFieldTelephoneComponent } from './ln-form-field-telephone/ln-form-field-telephone.component';
import { LnSocialMediaComponent } from './ln-social-media/ln-social-media.component';
import { LnTranslateComponent } from './ln-translate/ln-translate.component';

import { LnPublisherCardComponent } from './ln-publisher-card/ln-publisher-card.component';
import { CustomDatepickerI18n, LnDatePickerComponent } from './ln-date-picker/ln-date-picker.component';
import { TranslateModule } from '@ngx-translate/core';
import { LnGuestSelectorComponent } from './ln-guest-selector/ln-guest-selector.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceHintComponent } from '../service-hint/service-hint.component';
import { LnImagePickerComponent } from '../ln-image-picker/ln-image-picker.component';
import { ServiceIDComponent } from '../service-id/service-id.component';
import { SuccesfulReservationComponent } from '../succesful-reservation/succesful-reservation.component';
import { ReservationPriceChangedComponent } from '../reservation-price-changed/reservation-price-changed.component';
import { LnCarouselPublisherComponent } from '../ln-carousel-publisher/ln-carousel-publisher.component';
import { SuccessfulContactFormComponent } from '../successful-contact-form/successful-contact-form.component';
import { CardReservationStateComponent } from '../card-reservation-state/card-reservation-state.component';




const components = [
	LnCheckboxComponent,
	LnFormFieldInputComponent,
	LnFormFieldSelectComponent,
	LnButtonLoadingComponent,
	LnButtonComponent,
	LnFormFieldPasswordComponent,
	LnDialogInfoComponent,
	LnPriceSliderComponent,
	LnBackButtonComponent,
	LnCardMapsComponent,
	LnCardSkeletonComponent,
	LnCardServiceComponent,
	LnPaginatorComponent,
	LnFormFieldSelectMultipleComponent,
	LnFormFieldTelephoneComponent,
	LnTranslateComponent,
	LnSocialMediaComponent,
	LnDatePickerComponent,
	LnPublisherCardComponent,
	LnGuestSelectorComponent,
	LnImagePickerComponent,
	LnCarouselPublisherComponent,
	CardReservationStateComponent,
	SuccesfulReservationComponent,
	ServiceHintComponent,
	ServiceIDComponent,
	ReservationPriceChangedComponent,
	SuccessfulContactFormComponent

]

@NgModule({
	declarations: [
		...components,
	],
	providers: [DatePipe, CustomDatepickerI18n],
	exports: [
		...components
	],
	imports: [
		CommonModule,
		FormsModule,
		MaterialModule,
		ReactiveFormsModule,
		NgOptimizedImage,
		NgxSkeletonLoaderModule,
		RouterLink,
		TranslateModule,
		NgbDatepickerModule,


	]
})
export class LnComponentsModule {
}
