<div class=" principalDiv ">
	<div class=" margin w-full">
		<h4 class="overflow">{{'searchBar.textDateTitle'|translate}}</h4>
		<app-ln-date-picker (dateRangeSelected)="onDateRangeSelected($event)" [fromDate]="this.fromDate"
			[toDate]="this.toDate" (resetChange)="handleResetChange($event)" [reset]="this.reset"></app-ln-date-picker>

	</div>
	<div class="margin guestSelector w-full">
		<h4 class="overflow">{{'guestSelector.textTitle'|translate}}</h4>
		<app-ln-guest-selector [adults]="Adults" [pets]="Pets" [infants]="Infants" [children]="Children"
			(adultsChange)="handleAdultsChange($event)" (petsChange)="handlePetsChange($event)"
			(infantsChange)="handleInfantsChange($event)" (childrenChange)="handleChildrenChange($event)"
			(resetChange)="handleResetChange($event)" [reset]="this.reset" [mascotas]="true">
		</app-ln-guest-selector>
	</div>


	<div class="w-full margin">
		<h4 class="overflow">{{ 'searchBar.labelPriceRange' | translate }}</h4>
		<app-ln-price-slider (selectedMinValueEvent)="setMinValue($event)" (selectedMaxValueEvent)="setMaxValue($event)"
			[changedDefaultValues]=changedDefaultValues [valuesReceived]="sendPriceValues()"
			[startThumbValueInput]="minPrice" [startThumbValue]="minPrice" [endThumbValue]="maxPrice"
			[endThumbValueInput]="maxPrice">
		</app-ln-price-slider>
	</div>

	<div class="w-9/12 filterModalContainer" (click)="showModalFilter()">
		<button class="filterModalButton">
			<h4 class="mb-0">{{'searchBar.filters' | translate }}</h4>
			<img src="../../../../assets/icons/filters.svg" alt="">
		</button>
	</div>

	<div class="flex items-end" style="width: 10%;" *ngIf="changedDefaultValues">
		<app-clear-button (click)="clearFilters()" />

	</div>

	<span class="marginDiv buttonsDiv">

		<div class="flex mr-5" style="min-width: 90px">
			<ln-button [color]="'warn'" [icon]="'search'" [text]="'searchBar.btnSearch' | translate"
				(clickButton)="search()">
			</ln-button>
		</div>
		<div class="flex mr-5 overflow" style="min-width: 150px; ;">
			<ln-button [color]="'accent'" [text]="'searchBar.btnSearchInMap' | translate"
				(clickButton)="goToSearchInMap()">
			</ln-button>
		</div>
	</span>
	<h4 class="toDate pl-2">{{'searchBar.textHint'|translate}}</h4>
</div>