import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { StoreFiltersService } from '../../services/storeFilters.service';
import { Bloqueos } from '../../interfaces/bloqueos.interface';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-short-booking-card',
  templateUrl: './short-booking-card.component.html',
  styleUrl: './short-booking-card.component.css',
})
export class ShortBookingCardComponent implements OnInit{
  @Input() oferta: number = 0;
  @Input() nightPrice: number = 1;
  @Input() checkin: string | null = '';
  @Input() checkout: string | null = '';
  @Input()
  filterChange!: (nights: number) => void;
  @Input() fechasBloqueo: Bloqueos[] = [];
  @Input() maxGuests: number = 50;
  @Input() mascotas: boolean = false;

  fromDate: any;
  toDate: any;
  cantidadNoches: number = 1;

  Adults: number = 2;
  Pets: number = 0;
  Infants: number = 0;
  Children: number = 0;

  constructor(
    private storeFilters: StoreFiltersService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
	this.mascotas 
  }

  onDateRangeChanged($event: any) {
    // if (this.storeFilters.getFilter().fromDate !== this.fromDate) {
    //   this.fromDate = this.storeFilters.getFilter().fromDate;
    // }

    // if (this.toDate !== this.storeFilters.getFilter().toDate) {
    //   this.toDate = this.storeFilters.getFilter().toDate;
    // }

    if ($event.fromDate !== null && $event.toDate !== null) {
      const from = this.convertNgbDateToDate($event.fromDate);
      this.fromDate = this.convertDateToString(from);

      const toDate = this.convertNgbDateToDate($event.toDate);

      this.toDate = this.convertDateToString(toDate);
    }
    this.getCantidadNoches();
    // this.calcularPrecioTotal();
    this._cdr.detectChanges();
  }

  getCantidadNoches() {
    // Convertir las cadenas de texto a objetos Date
    let checkIn = new Date(this.fromDate);
    let checkOut = new Date(this.toDate);

    // Calcular la diferencia de tiempo en milisegundos
    const diffTime = Math.abs(checkOut.getTime() - checkIn.getTime());
    if (this.cantidadNoches != Math.ceil(diffTime / (1000 * 60 * 60 * 24))) {
      this.filterChange(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
    }

    // Convertir la diferencia de tiempo en días y redondear hacia arriba
    this.cantidadNoches = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  handleChildrenChange($event: number) {
    this.storeFilters.setFilter({ Children: $event });
  }
  handlePetsChange($event: number) {
    this.storeFilters.setFilter({ Pets: $event });
  }

  handleInfantsChange($event: number) {
    this.storeFilters.setFilter({ Infants: $event });
  }
  handleAdultsChange($event: number) {
    this.storeFilters.setFilter({ Adults: $event });
  }

  convertNgbDateToDate(ngbDate: NgbDate): Date {
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }

  convertDateToString(date: Date): string {
    return date.toISOString().split('T')[0]; // Formato YYYY-MM-DD
  }

}
