<button mat-button class="guestContainer" id="guestButton" [ngClass]="customClass" [matMenuTriggerFor]="menu" #trigger="matMenuTrigger">
  <span>{{ buttonText }}</span>
  <img src="../../../../../assets/icons/arrow-down.svg" alt="Guest">
</button>

<mat-menu #menu="matMenu" class="guestMenu" panelClas="guestMenu">
  <div (click)=" $event.stopPropagation()">
    <form class="menu-form">
      <div class="item">
        <h3>{{'guestSelector.textAdults'|translate}}</h3>
        <span class="flex">
          <h4>{{'guestSelector.textAge1'|translate}}</h4>
          <div class="flex counter">
            <button class="decrease" [disabled]="adults === 1" mat-button (click)="decreaseAdults()">
              <img  src="../../../../../assets/icons/iconDecrease.svg" alt="Decrease">
            </button>
            <span>{{adults}}</span>
            <button class="increase" mat-button [disabled]="maxGuests===guests" (click)="increaseAdults()">
              <img  src="../../../../../assets/icons/iconIncrease.svg" alt="Increase">
            </button>
            
          </div>
        </span>
      </div>
      <div class="line"></div>
      <span class="item">
        <h3>{{'guestSelector.textChildren'|translate}}</h3>
        <span class="flex">
          <h4>{{'guestSelector.textAge2'|translate}}</h4>
          <div class="flex counter">
            <button class="decrease" [disabled]="children === 0" mat-button (click)="decreaseChildren()">
              <img src="../../../../../assets/icons/iconDecrease.svg" alt="Decrease">
            </button>
            <span>{{children}}</span>
            <button class="increase" mat-button [disabled]="maxGuests===guests" (click)="increaseChildren()">
              <img src="../../../../../assets/icons/iconIncrease.svg" alt="Increase"></button>
          </div>
        </span>
      </span>
      <div class="line "></div>
      <span class="item">
        <h3>{{'guestSelector.textInfants'|translate}}</h3>
        <span class="flex">
          <h4>{{'guestSelector.textAge3'|translate}}</h4>
          <div class="flex counter">
            <button class="decrease" [disabled]="infants === 0" mat-button (click)="decreaseInfants()">
              <img src="../../../../../assets/icons/iconDecrease.svg" alt="Decrease">
            </button>
            <span>{{infants}}</span>
            <button class="increase" mat-button (click)="increaseInfants()"><img src="../../../../../assets/icons/iconIncrease.svg" alt="Increase"></button>
          </div>
        </span>
      </span>
      <div class="line "></div>
      <span class="item">
        <span class="flex ">
          <h3>{{'guestSelector.textPets'|translate}}</h3>
          <div class="flex counter">
            <button class="decrease" [disabled]="pets === 0" mat-button (click)="decreasePets()">
              <img src="../../../../../assets/icons/iconDecrease.svg" alt="Decrease">
            </button>
            <span>{{pets}}</span>
            <button class="increase" [disabled]="isBedBreakfast || pets===maxPets || !mascotas" mat-button (click)="increasePets()">
              <img src="../../../../../assets/icons/iconIncrease.svg" alt="Increase">
            </button>
          </div>
        </span>
      </span>

      <span class="highlighted-text">
        <h5>{{'guestSelector.textBottom1'|translate}} </h5><a (click)="showAnimal()">{{'guestSelector.textBottom2'|translate}}</a>
      </span>
    </form>
  </div>
</mat-menu>