
<div class="titleHeader">
    <div>
        <h2>{{ 'realStateBAB.found' | translate }}</h2>
    </div>
    <div class="vertodo">
        <a class="see-more cursor-pointer" (click)="goToSeeAll()">{{ 'home.seeAll' |
            translate }}</a>
    </div>
</div>
<div class="swiper">
    <div class="swiper-wrapper pb-4 pt-3 ">
        <!-- Muestra los datos cuando loading es falso -->
        <ng-container *ngIf="isData; else skeleton">
            <div class="swiper-slide" *ngFor="let element of carouselData" style="margin-right: 0 !important;">
                <mat-card class="custom-card publisherCard" style="cursor: pointer; "
                    (click)="goToDetail(element.idPropiedad)">
                    <mat-card-content>
                        <div class="card-content">
                            <div class="image-container">
                                <img style="max-height: 100px;" *ngIf="element.imagenes.length===0 || element.imagenes[0].url === null"
                                    class="imgRealState centered-image"
                                    src="../../../../../assets/images/logo-inmobiliaria-default.png">
                                <img style="max-height: 100px;" *ngIf="element.imagenes.length>0 && element.imagenes[0].url !== null"
                                    class="imgRealState centered-image" [src]="element.imagenes[0].url">
                            </div>
    
                            <div class="pt-3" id="publisherAndPriceRow">
                                <div class="text-center">
                                    <span class="name colorCafe">{{ element.nombre }}</span>
                                </div>
                                <div class="text-center">
                                    <span class="view" style="cursor: pointer;"
                                        (click)="goToDetail(element.idPropiedad)">{{ 'realStateBAB.properties'
                                        | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
    
            <!---Card de ver todos-->
            <div class="swiper-slide">
                <mat-card class="custom-card publisherCard" (click)="goToSeeAll()"
                    style="cursor: pointer; display: flex; justify-content: center; align-items: center;">
                    <mat-card-content>
                        <div class="card-content">
                            <div class="pt-3 " id="publisherAndPriceRow" style="padding-top: auto;">
                                <span class="view" (click)="goToSeeAll()" style="cursor: pointer;">{{'realStateBAB.viewAll'
                                    | translate }}</span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </ng-container>
    </div>
    <!--  pagination -->
    <div class="swiper-pagination"></div>
    <!-- navigation buttons -->
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
</div>


<!-- Muestra el skeleton si loading es verdadero -->
 <ng-template #skeleton>
    <div class="swiper-slide" *ngFor="let i of [].constructor(3);" style="align-items: center;"> 
        <mat-card class="custom-card publisherCard">
            <div class="slideSkeleton">
                <div class="skeleton"></div>
            </div>
        </mat-card>
    </div>
    
    <div class="swiper-slide">
        <mat-card class="custom-card publisherCard" (click)="goToSeeAll()"
            style="cursor: pointer; display: flex; justify-content: center; align-items: center;">
            <mat-card-content>
                <div class="card-content">
                    <div class="pt-3 " id="publisherAndPriceRow" style="padding-top: auto;">
                        <span class="view" (click)="goToSeeAll()" style="cursor: pointer;">{{'realStateBAB.viewAll'
                            | translate }}</span>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    
</ng-template>


