import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject, input } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { MatDialog } from '@angular/material/dialog';
import { ServiceIDComponent } from '../service-id/service-id.component';
import { FormControl } from '@angular/forms';
import { TokenService } from '../../services/token.service';
import { UserUseCaseService } from '../../../core/usecase/user-use-case.service';

@Component({
  selector: 'ln-image-picker',
  templateUrl: './ln-image-picker.component.html',
  styleUrls: ['./ln-image-picker.component.css', './ln-image-picker2.component.css']
})
export class LnImagePickerComponent implements OnChanges, OnInit {

  @Input() control: FormControl = new FormControl<any>({});
  @Input() error = 'Campo requerido';
  @Input() hasDocumentUrl = false;
  @Input() fileName: string = "";
  @Input() url: string = "";

  @Output() fileNameChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() File: EventEmitter<File> = new EventEmitter<File>();


  selectedImage: string | ArrayBuffer | null = null;
  showMessage: boolean = false;
  showEye: boolean = false;
  showDelete: boolean = false;
  esp: boolean = true;
  currentLanguage = "es";
  hoverText: string | undefined;
  uploading: boolean = false;
  percentLoaded: number = 0;

  constructor(
    private _translationService: TranslationService,
    private tokenService: TokenService,
    private cdr: ChangeDetectorRef,
    private _userRepository: UserUseCaseService
  ) {
    _translationService.onLangChange.subscribe(() => {

      this.currentLanguage = this._translationService.getCurrentLang;
      if (this.currentLanguage === 'en')
        this.esp = false;
      else {
        this.esp = true;
      }
      this.toggleMessage();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }


  ngOnInit(): void {
    if (this.fileName)
      this.hasDocumentUrl = true;
    this.selectedImage = this.url;
  }


  onFileSelected(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    const input = event.target as HTMLInputElement;

    if (input.files && input.files[0]) {
      const file = input.files[0];
      const fileType = file.type;
      const fileSize = file.size / (1024 * 1024);

      if (fileSize > 10) {
        console.warn('El archivo seleccionado supera el límite de 10 MB');
        return;
      }

      if (fileType.startsWith('image/') || fileType === 'application/pdf') {

        // Llamamos a la función asíncrona que manejará la carga del archivo
        this.readFileAsync(file, fileType).then(() => {
          console.log('La carga del archivo ha finalizado.');

        });
      } else {
        console.error('El archivo seleccionado no es una imagen o un PDF');
      }
    }
  }

  // Función asíncrona que devuelve una Promesa y espera a que se complete la carga del archivo
  private async readFileAsync(file: File, fileType: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const reader = new FileReader();
      this.uploading = true;

      reader.onprogress = (e) => {
        if (e.lengthComputable) {
          this.percentLoaded = Math.round((e.loaded / e.total) * 100);
        }
      };

      reader.onload = () => {
        this.selectedImage = reader.result as string;
        this.control.setValue(reader.result as string);
        this.cdr.detectChanges();
        this.fileName = file.name.trim();
        this.fileNameChange.emit(this.fileName);
        this.File.emit(file);
        this.hasDocumentUrl = true;
        this.uploading = false;
        resolve(); // Resolvemos la promesa cuando la carga se completa

      };

      reader.onerror = () => {
        reject(new Error('Error al leer el archivo')); // Rechazamos la promesa en caso de error
      };

      if (fileType.startsWith('image/')) {
        reader.readAsDataURL(file);
      } else if (fileType === 'application/pdf') {
        reader.readAsArrayBuffer(file);
        reader.onloadend = () => {
          const base64String = btoa(
            new Uint8Array(reader.result as ArrayBuffer)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          this.selectedImage = `data:application/pdf;base64,${base64String}`;
          this.control.setValue(this.selectedImage);
          this.cdr.detectChanges();
          resolve(); // Resuelve la promesa después de la conversión
        };
      }
    });
  }



  onImageOpen(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    // Obtener el tipo de archivo de la URL o del nombre del archivo
    const fileType = this.url ? this.url.split('.').pop()?.toLowerCase() : this.fileName.split('.').pop()?.toLowerCase();

    // Función genérica para abrir contenido en una nueva ventana
    const openInNewWindow = (content: string, isImage: boolean) => {
      const newWindow = window.open();
      if (!newWindow) {
        console.error('Failed to open new window');
        return;
      }

      if (isImage) {
        const img = new Image();
        img.src = content;
        newWindow.document.body.appendChild(img);
      } else {
        const iframe = document.createElement('iframe');
        iframe.src = content;
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.style.border = 'none';
        newWindow.document.body.appendChild(iframe);
      }
    };

    // Función para crear una URL de Blob a partir de un ArrayBuffer de PDF
    const createPdfUrlFromArrayBuffer = (arrayBuffer: ArrayBuffer) => {
      try {
        const pdfBlob = new Blob([arrayBuffer], { type: 'application/pdf' });
        return URL.createObjectURL(pdfBlob);
      } catch (error) {
        console.error('Error al crear la URL del PDF:', error);
        return null;
      }
    };

    // Lógica para manejar archivos PDF
    if (fileType === 'pdf') {
      const pdfContent =
        this.selectedImage instanceof ArrayBuffer
          ? createPdfUrlFromArrayBuffer(this.selectedImage)
          : typeof this.selectedImage === 'string' && this.selectedImage.startsWith('data:application/pdf')
            ? this.selectedImage
            : this.url;

      if (pdfContent) {
        openInNewWindow(pdfContent, false);
      } else {
        console.error('No hay datos para abrir el PDF');
      }
      return;
    }

    // Lógica para manejar imágenes
    const imageContent = this.selectedImage || this.url;

    if (imageContent) {
      openInNewWindow(imageContent as string, true);
    } else {
      console.error('No hay datos para abrir la imagen');
    }
  }


  onImageDelete(event: Event) {
    if (this.url.length > 0) {
      this._userRepository.deleteFile(this.fileName).subscribe(
        {
          next: (response) => {
            const file = undefined
            event.stopPropagation();
            event.preventDefault();
            this.selectedImage = null;
            this.hasDocumentUrl = false;
            this.url = "";
            this.fileName = "";

            this.control.setValue(null);
            this.showDelete = false;
            this.cdr.detectChanges();

            this.fileNameChange.emit(this.fileName);
            this.File.emit(file);
          },
          error: (error) => {
            console.error('Error al eliminar el archivo', error);
          }
        }
      );
    } else {
      event.stopPropagation();
      event.preventDefault();
      this.selectedImage = null;
      this.hasDocumentUrl = false;
      this.url = "";
      this.fileName = "";

      this.control.setValue(null);
      this.showDelete = false;
      this.cdr.detectChanges();

      this.fileNameChange.emit(this.fileName);
      this.File.emit(undefined);
    }
  }


  toggleMessage() {
    if (this.currentLanguage === 'es') {
      this.hoverText = 'Para la reserva será necesario este documento de identidad.'
    } else if (this.currentLanguage === 'en') {
      this.hoverText = 'This ID will be necessary for the reservation.';
    } else {
      this.hoverText = undefined;
    }
  }


  private _matDialog = inject(MatDialog);
  showHint() {
    this._matDialog
      .open(ServiceIDComponent, {
        disableClose: true,
        panelClass: 'app-service-id',
      })
  }

  requiredFileType(control: FormControl): { [key: string]: any } | null {
    return control.value ? null : { required: true };
  }

  isControlInvalid(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }
}
