<footer class="p-4 bg-white shadow md:p-6 footer">
    <div class="flex flex-wrap sm:cols-3 justify-between mx-auto">
        <div class="flex-row">
            <div class="flex gap-2">
                <a href="#" class="flex mb-5 ">
                    <img src="../../../../assets/icons/logo.svg" class="logo-properties" alt="Logo" />
                </a>
                <a [href]="redirectUrl" class="flex mb-5">
                    <img src="../../../../assets/icons/portal-inmobiliario.svg" class="logo-portal" alt="Logo" />
                </a>
            </div>
            <p class="max-w-lg mb-3 textFooter">
                {{'footer.text1' | translate}} <br>
                <!-- {{'footer.text2' | translate}} -->
            </p>
        </div>
        <div class="servicesDiv">
            <ul class="flex flex-wrap mb-6 text-sm text-gray-500 sm:mb-0">
                <li>
                  <!-- <a routerLink="/contact-page-form"   -->
                  <a class="mr-4 md:mr-6" routerLink="/contact-page-form"> {{'footer.linkContact' | translate}}</a>
                </li>
                 <li>
                    <a routerLink="/services" class="mr-4 md:mr-6 ">{{'footer.linkServices' | translate}}</a>
                </li>
                <li>
                    <a routerLink="/us" class="mr-4 md:mr-6 ">{{'footer.linkUs' | translate}}</a>
                </li>
                <li>
                    <a routerLink="/blog" class="mr-4 md:mr-6">Blog</a>
                </li>
                <!-- <li>
                    <a routerLink="/services-form">{{'footer.linkPublishProperty' | translate}}</a>
                </li>  -->
            </ul>
            <div class="icons mr-4">
                <a href="https://wa.me/+50245085136" class="pr-4">
                    <img class="icon" src="../../../../assets/icons/WhatsappVerde.svg" alt="" >
                </a>
                <a href="https://www.instagram.com/lunanueva.portal/">
                    <img class="icon" src="../../../../assets/icons/InstagramRojo.svg" alt="" >
                </a>
            </div>
        </div>
    </div>
    <div class="seiza">
        <a (click)="goToSeizaPage()"><p>{{ 'footer.linkDevelopedBy1' | translate }} <mat-icon>favorite</mat-icon>{{'footer.linkDevelopedBy2' | translate }} </p></a>
    </div>
</footer>
