import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDecimalSeparator'
})
export class CustomDecimalSeparatorPipe implements PipeTransform {

  transform(value: number | string, ...args: unknown[]): string {
    if (typeof value === 'number') {
      value = value.toString();
    }
    // Cambiar el punto por coma y añadir 'km'
    let formattedValue = value.replace('.', ',') + ' km';
    return formattedValue;
  }
}