import {
  Component,
  Input,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
  EventEmitter,
  Output,
} from "@angular/core";
import { Subject, Subscription, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { FiltersInterface, sideFilters } from "../../interfaces/filters.interface";
import { TranslationService } from "../../services/translation.service";
import { MatDialog } from "@angular/material/dialog";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { FiltersSideMenuComponent } from "../../../views/nav/filters-side-menu/filters-side-menu.component";
import { AlojamientosFilters } from '../../interfaces/alojamientos-filters.interface';
import { MapService } from "../../../core/services/map-trigger.service";
import { IsPetService } from "../../services/isPet.service";
import { StoreFiltersService } from "../../services/storeFilters.service";

@Component({
  selector: "ln-search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.css", "./2search-bar.component.css", "./3search-bar.component.css"],
})
export class SearchBarComponent implements OnInit, OnDestroy {

  @ViewChild("togglePrice") togglePrice: ElementRef = {} as ElementRef;
  @ViewChild("showRangeDiv") menu: ElementRef = {} as ElementRef;

  searchMapFilters: AlojamientosFilters;

  @Input() filters: FiltersInterface = {};
  @Input() rangeValues: string = "";
  @Input() selectedMinValue: number = 0;
  @Input() selectedMaxValue: number = 1000;
  @ViewChild("range") range: ElementRef = {} as ElementRef;

  Adults: number = 2;
  Pets: number = 0;
  Infants: number = 0;
  Children: number = 0;

  changedDefaultValues!: boolean;
  showRange: boolean = false;
  subscriptions: Subscription[] = [];
  minPrice: number = 50;
  maxPrice: number = 1000;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  defaultPrice: boolean = true;
  reset: boolean = false;
  take: number = 6;
  page: number = 0;
  destroy$ = new Subject<void>();
  sideFilters: sideFilters | undefined;

  constructor(
    private _router: Router,
    public translate: TranslationService,
    private petService: IsPetService,
    private storeFilterService: StoreFiltersService,
  ) {
    this.searchMapFilters = {
      page: 0,
      take: 6,
      minPrice: 50,
      maxPrice: 1000,
      fromDate: '',
      toDate: '',
      permiteMascotas: 0,
      Adults: 2,
      Children: 0,
      Infants: 0,
      Pets: 0,
    } as AlojamientosFilters;
  }

  async ngOnInit() {
    this.getFilters();
    this.loadSearchAmountParams();
    await this._setFilters();
    this.detectBoundariesChange();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.subscriptions)
      this.subscriptions.forEach((subs) => subs.unsubscribe());
  }

  loadSearchAmountParams(): void {
    const storedFilters = this.storeFilterService.getAnyFilter();

    if (storedFilters.minPrice != undefined) {
      this.setMinValue(storedFilters.minPrice);
    }

    if (storedFilters.maxPrice != undefined) {
      this.setMaxValue(storedFilters.maxPrice);
    }
  }

  setMinValue(value: number) {
    this.minPrice = value;
    this.selectedMinValue = value;
    this.changedDefaultValues = true;
    this.defaultPrice = false;
    this.storeFilterService.setFilter({ minPrice: this.minPrice });
  }

  setMaxValue(value: number) {
    this.maxPrice = value;
    this.selectedMaxValue = value;
    this.changedDefaultValues = true;
    this.defaultPrice = false;
    this.storeFilterService.setFilter({ maxPrice: this.maxPrice });
  }

  sendPriceValues() {
    let valuesReceived = {};
    if (this.changedDefaultValues) {
      valuesReceived = {
        minPrice: this.searchMapFilters.minPrice,
        maxPrice: this.searchMapFilters.maxPrice,
      };
    }

    return valuesReceived;
  }

  getFilters() {
    this.searchMapFilters = { ...this.storeFilterService.getAnyFilter() };
    this.storeFilterService.setFilter(this.searchMapFilters);

    this.storeFilterService.filterSideChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(filterSide => {
        this.filters = { ...this.filters, ...filterSide };
        if (this.filters) {
          this.changedDefaultValues = true;
        }
      });

    const storedFilters = this.storeFilterService.getAnyFilter();

    if (storedFilters.fromDate) {
      this.fromDate = this.convertStringToNgbDate(storedFilters.fromDate);
    }

    if (storedFilters.toDate) {
      this.toDate = this.convertStringToNgbDate(storedFilters.toDate);
    }

    if (storedFilters.Adults) {
      this.Adults = parseInt(storedFilters.Adults);
    } else {
      this.Adults = 2;
    }

    if (storedFilters.Children) {
      this.Children = parseInt(storedFilters.Children);
    } else {
      this.Children = 0;
    }

    if (storedFilters.Pets) {
      this.Pets = parseInt(storedFilters.Pets);
    } else {
      this.Pets = 0;
    }

    if (storedFilters.Infants) {
      this.Infants = parseInt(storedFilters.Infants);
    } else {
      this.Infants = 0;
    }
  }

  private _setFilters() {
    let max;
    let min;
    let fromDate;
    let toDate;
    let permiteMascotas;
    if (this.selectedMinValue >= 0 && this.changedDefaultValues) {
      min = this.selectedMinValue;
    }
    if (this.selectedMaxValue >= 0 && this.changedDefaultValues) {
      max = this.selectedMaxValue;
    }
    if (this.fromDate && this.toDate) {
      this.searchMapFilters.fromDate = fromDate;
      this.searchMapFilters.toDate = toDate;
      fromDate = this.convertNgbDateToString(this.fromDate);
      toDate = this.convertNgbDateToString(this.toDate);
    }

    if (this.Pets >= 1) {
      permiteMascotas = 1;
    } else if (this.Pets == 0) {
      permiteMascotas = 0;
    }

    const filters: any = {
      page: this.page,
      take: this.take,
      permiteMascotas: permiteMascotas || 0,
      Adults: this.Adults,
      Children: this.Children,
      Infants: this.Infants,
      Pets: this.Pets
    };

    if (this.searchMapFilters.fromDate) {
      filters.fromDate = this.searchMapFilters.fromDate;
    }

    if (this.searchMapFilters.toDate) {
      filters.toDate = this.searchMapFilters.toDate;
    }

    this.storeFilterService.setFilter(filters);

    this.searchMapFilters = {
      ...this.searchMapFilters,
      ...filters
    };
  }

  convertNgbDateToString(date: NgbDate | null): string {
    if (date) {
      return `${date.year}-${date.month}-${date.day}`;
    }
    return '';
  }

  convertStringToNgbDate(dateStr: string): NgbDate {
    const dateParts = dateStr.split('-').map(part => parseInt(part, 10));
    return new NgbDate(dateParts[0], dateParts[1], dateParts[2]);
  }

  clearDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    this.searchMapFilters.fromDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    this.searchMapFilters.toDate = `${tomorrow.getFullYear()}-${tomorrow.getMonth() + 1}-${tomorrow.getDate()}`;
  }

  clearFilters() {
    this.clearDate();

    this.searchMapFilters.page = 0;
    this.searchMapFilters.take = 6;
    this.minPrice = 50;
    this.maxPrice = 1000;
    this.searchMapFilters.maxPrice = this.maxPrice;
    this.searchMapFilters.minPrice = this.minPrice;
    this.selectedMaxValue = this.maxPrice;
    this.selectedMinValue = this.minPrice;
    this.showRange = false;
    this.rangeValues = "";

    this.defaultPrice = true;
    this.reset = true;

    this.petService.petQuantity = 0;
    this.petService.pet = false;

    this.storeFilterService.clearAllFilters();
    this.changedDefaultValues = false;

    this.Adults = 2;
    this.Pets = 0;
    this.Infants = 0;
    this.Children = 0;
    this.handleResetChange(this.reset);
  }

  setChangedDefaultValues() {
    this._setFilters();
    this.changedDefaultValues = true;
  }

  handleAdultsChange(adults: number) {
    this.Adults = adults;
    const adu = { 'Adults': this.Adults };
    if (adults > 0) {
      this.setChangedDefaultValues();
      this.storeFilterService.setFilter(adu);
      this.searchMapFilters.Adults = this.Adults;
    } else if (adults === 0 && this.defaultPrice!) {
      this.storeFilterService.setFilter(adu);
      if (this.Children !== 0 || this.Infants !== 0 || this.Pets !== 0 || this.toDate || this.fromDate) {
        return;
      } else {
        this.changedDefaultValues = false;
      }
    }
  }

  handleChildrenChange(children: number) {
    this.Children = children;
    let chil = { 'Children': this.Children };
    if (children > 0) {
      this.setChangedDefaultValues();
      this.searchMapFilters.Children = this.Children;
      this.storeFilterService.setFilter(chil);
    } else if (children === 0 && this.defaultPrice!) {
      this.storeFilterService.setFilter(chil);
      if (this.Adults !== 0 || this.Infants !== 0 || this.Pets !== 0 || this.toDate || this.fromDate) {
        return;
      } else {
        this.changedDefaultValues = false;
      }
    }
  }

  handlePetsChange(pets: number) {
    this.Pets = pets;
    this.petService.petQuantity = pets;
    let pet = { 'Pets': this.Pets };
    if (pets > 0) {
      this.setChangedDefaultValues();
      this.searchMapFilters.Pets = this.Pets;
      this.searchMapFilters.permiteMascotas = 1;
      this.storeFilterService.setFilter(pet);
      this.storeFilterService.setFilter({ permiteMascotas: 1 });
    } else {
      delete this.searchMapFilters.permiteMascotas;
      //this.searchMapFilters.permiteMascotas = 0;
      this.petService.petQuantity = 0;
      this.searchMapFilters.Pets = pets;
      this.storeFilterService.setFilter(pet);
      //this.storeFilterService.setFilter({ permiteMascotas: 0 });
      if (this.defaultPrice && (this.Children !== 0 || this.Infants !== 0 || this.Adults !== 0 || this.toDate || this.fromDate)) {
        return;
      } else {
        this.changedDefaultValues = false;
      }
    }
  }

  handleInfantsChange(infants: number) {
    this.Infants = infants;
    let inf = { 'Infants': this.Infants };
    if (infants > 0) {
      this.searchMapFilters.Infants = this.Infants;
      this.setChangedDefaultValues();
      this.storeFilterService.setFilter(inf);
    } else if (infants === 0 && this.defaultPrice!) {
      this.storeFilterService.setFilter(inf);
      if (this.Children !== 0 || this.Adults !== 0 || this.Pets !== 0 || this.toDate || this.fromDate) {
        return;
      } else {
        this.changedDefaultValues = false;
      }
    }
  }

  @Output() searchMapButtonClicked = new EventEmitter<void>();
  goToSearchInMap() {
    this._setFilters();
    const filters = this.storeFilterService.getAnyFilter();

    this._router.navigate(["search-map"], {
      queryParams: {
        ...filters,
        changedDefaultValues: this.changedDefaultValues,
      },
    });

    this.searchMapButtonClicked.emit();
  }

  @Output() searchButtonClicked = new EventEmitter<void>();
  search() {
    this._setFilters();
    this.storeFilterService.deleteFilter('north');
    this.storeFilterService.deleteFilter('south');
    this.storeFilterService.deleteFilter('west');
    this.storeFilterService.deleteFilter('east');

    const query = { ...this.storeFilterService.getAnyFilter() };

    this._router.navigate(['/'], {
      queryParams: {
        ...query,
        changedDefaultValues: this.changedDefaultValues,
      },
    });

    this.searchButtonClicked.emit();
  }

  private _matDialog = inject(MatDialog);
  showModalFilter() {
    this._matDialog
      .open(FiltersSideMenuComponent, {
        position: {
          top: '0px',
          right: '0px'
        },
        maxWidth: '571px',
        maxHeight: '98989898px',
        height: '100%',
        width: '100%',
      })
      .afterOpened().subscribe(() => {
        this.storeFilterService.filterChanged.subscribe((filterSide) => {
          this.searchButtonClicked.emit();
        });
      });
  }

  onDateRangeSelected(event: { fromDate: NgbDate | null, toDate: NgbDate | null }) {
    if (event.fromDate) {
      this.fromDate = event.fromDate;
    }

    if (event.toDate) {
      this.toDate = event.toDate;
    }

    if (this.fromDate || this.toDate) this.setChangedDefaultValues();
  }

  handleResetChange(reset: boolean) {
    this.reset = reset;
  }

  detectBoundariesChange() {
    const sideFilters: sideFilters = this.storeFilterService.getFilterSide();
    for (const filter in sideFilters) {
      if (sideFilters[filter as keyof sideFilters]) {
        // El filtro está en los filtros laterales
      } else {
        // El filtro está en los filtros laterales pero no en la URL
        this.storeFilterService.deleteFilterSide({ [filter]: 1 });
      }
    }
  }
}