import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: any, currencySymbol: string = '$'): string {
    if (value === null || value === undefined || isNaN(Number(value))) {
      return '';
    }

    const numericValue = Number(value);
    // Convertir el número en string con formato de miles y decimales
    const formattedValue = numericValue
      .toFixed(2)
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${currencySymbol}${formattedValue}`;
  }
}