import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { mergeMap, Observable, startWith, Subject, Subscription, take, takeUntil, tap } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";

import {
    ControlsKeyImmutableType,
    FilterContainerType,
    FiltersChips, FilterSideMenuMeta,
    FilterSideMenuMeta as ConfigMeta,
    FilterValues,
    FormFilterType
} from "./meta/filter-side-menu-meta";
import { FiltersInterface } from "../../../shared/interfaces/filters.interface";
import { PropertyTypeEntity } from "../../../data/entity/property-type-entity";
import { PropertyTypesPageActions, PropertyTypesSelectors } from './../../../ngrx/index';
import { TranslationService } from "../../../shared/services/translation.service";

import { TypeFilterService } from '../../../core/services/type-filter.service';
import { IsPetService } from '../../../shared/services/isPet.service';
import { StoreFiltersService } from '../../../shared/services/storeFilters.service';


@Component({
    selector: 'ln-filters-side-menu',
    templateUrl: './filters-side-menu.component.html',
    styleUrls: ['./filters-side-menu.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersSideMenuComponent implements OnInit, OnDestroy {

    @Input() label: string = 'filtersSideMenu.textApartmentsForSale';
    @Input() workspace: string = 'filtersSideMenu.workspace';

    filters!: FiltersInterface;
    filtersConfigMeta = ConfigMeta;
    filtersList!: FilterContainerType[];
    filtersServicesList!: FilterValues[];
    filtersValuesFlat!: FilterValues[];


    propertyTypes$!: Observable<PropertyTypeEntity[]>;
    private _disposed$ = new Subject<void>();
    subscriptions: Subscription[] = [];
    sideMenuTexts: any = {};
    private destroy$ = new Subject<void>();
    isPetSelected = false
    selectedFilters: Partial<FiltersInterface> = {};

    typeAccomodationService: TypeFilterService = inject(TypeFilterService);

    isSelected: boolean = false;
    valuePet = {
        "key": 1,
        "value": "Pet friendly",
        "filterType": "permiteMascotas",
        "isSelected": true
    } as FilterValues;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _cdr: ChangeDetectorRef,
        private _router: Router,
        private _store: Store,
        private translate: TranslationService,
        private petService: IsPetService,
        private filterService: StoreFiltersService
    ) {
    }

    ngOnInit(): void {
        this.translate.onLangChange
            .pipe(
                takeUntil(this._disposed$),
                startWith(() => this.translate.getCurrentLang),
                mergeMap(() => this.translate.getTranslation('filtersSideMenu'))
            )
            .subscribe(data => {
                this.sideMenuTexts = data;
                this._createFilterStructure(); // Make sure this method populates this.filtersList
                this._processQueryParams();
            });
        this.isPetSelected = this.petService.pet;
        this.selectedFilters = this.filterService.getFilterSide();
        if (Object.keys(this.selectedFilters).length !== 0) {
           if (this.isPetSelected){
            this.isSelected = true;            
            }
        }

        if (this.isPetSelected) {
            this.filterWasSelected(this.valuePet);
        }
        this._getPropertyTypes();
        //this.disableCategory();
        this._initialize();

    }

    private _processQueryParams(): void {
        this.isPetSelected = this.petService.pet;
        this.selectedFilters = this.filterService.getFilterSide();
        const queryParams = this._activatedRoute.snapshot.queryParams;

        if (!this.filtersList) {

            return;
        }

        // Process filtersList
        for (const key in queryParams) {
            if (queryParams.hasOwnProperty(key)) {
                // Search in filtersList
                const filter = this.filtersList.find(filter => {
                    const isMatch = filter.filterValue.some(value => value.filterType === key && value.key.toString() === queryParams[key]);
                    if (isMatch) {

                    }
                    return isMatch;
                });

                // If filter found in filtersList, handle it
                if (filter) {
                    const selectedValue = filter.filterValue.find(value => value.filterType === key && value.key.toString() === queryParams[key]);
                    if (selectedValue) {
                        this.filterWasSelected(selectedValue);
                        this.filterService.setFilterSide({ [key]: queryParams[key] });
                    } else {

                    }
                } else {

                }
            }
        }

        // Process filtersServicesList
        let filterService = []; // Initialize as an array for collecting filters

        for (const key in queryParams) {
            if (queryParams.hasOwnProperty(key)) {
                // Search in filtersServicesList
                if(key === "permiteMascotas"){
                    if (!this.isPetSelected){
                        this.filterWasSelected(this.valuePet);
                    this.filterService.setFilterSide({ [this.valuePet.filterType]: 0 });
                    // filterService.push(this.valuePet);
                    } else {
                        this.filterWasSelected(this.valuePet);
                        this.filterService.setFilterSide({ [this.valuePet.filterType]: 1 });
                        filterService.push(this.valuePet); // Add to the array instead of setting    
                    }
                } else {
                    const serviceFilter = this.filtersServicesList.find(filter => filter.filterType === key && filter.key.toString() === queryParams[key]);
                    if (serviceFilter) {
                        // Handle selection or any specific action
                            this.filterWasSelected(serviceFilter);
                            this.filterService.setFilterSide({ [key]: queryParams[key] });
                            filterService.push(serviceFilter); //Add to the array instead of setting                    
                    }
                }
            }
        }
        this.filterToSelect = filterService;
    }



    filterWasSelected(item: FilterValues) {
        // if (item.filterType === 'permiteMascotas' && this.petService.petQuantity === 0) {
        if (!this.isPetSelected && item.value=="Pet friendly"){
                this.setFilterParams({ ...this.selectedFilters, [item.filterType]: 0 });
                const filter = { [item.filterType]: 0 }
                this.isSelected = false;
                this.filterService.setFilterSide(filter);
        } else {
            if (item.value==='Pet friendly') {
                this.petService.petQuantity = 1;
                }
                this.setFilterParams({ ...this.selectedFilters, [item.filterType]: item.key });
                const filter = { [item.filterType]: item.key }
                this.isSelected = true;
                this.filterService.setFilterSide(filter);
        }        
    }

    filterWasRemoved(item: FiltersChips) {
        // Verificar si se está eliminando el filtro de mascotas
        if (item.formControl === 'petFriendly') {
            // Restablecer la cantidad de mascotas seleccionadas
            this.petService.petQuantity = 0;
            this.isPetSelected = false;
            
        }
        this.filterService.deleteAnyFilter({ [item.formControl]: item.id });

        // Remover el filtro seleccionado
        const control = item.formControl as FormFilterType;
        this._removeFilters(control);

        // Verificar si aún quedan filtros seleccionados
        const remainingFilters = this.getFiltersSelected();
        this.isSelected = remainingFilters.length > 0;
        this._cdr.detectChanges();
    }



    filtersServicesWasRemoved(item: FilterValues) {
        const control = item.filterType as FormFilterType;
        this._removeFilters(control)
        this._cdr.detectChanges();
        //console.log(item)
    }
    filterToSelect: any;



    getFiltersSelected() {
        const filters: FiltersChips[] = [];
        for (let controlsKey in this.selectedFilters) {
            const id = (this.selectedFilters as any)[controlsKey as FormFilterType]?.toString() ?? '';
            let isImmutable = false;
            let value = '';

            if (this.filtersConfigMeta.controlsKeyImmutables[controlsKey as ControlsKeyImmutableType]) {
                isImmutable = true;
                value = this._getImmutablesValues(controlsKey as ControlsKeyImmutableType, id)
            } else if (id !== null) {
                //     TODO => la comparacion debe ser con (!== null) porque el id puede ser 0 y no lo tomaria.
                value = this._getFormFieldsControlValues(controlsKey as FormFilterType, id);
            }



            if (value) filters.push(
                {
                    id,
                    value,
                    isImmutable,
                    formControl: controlsKey
                }
            )
        }
        return filters;
    }

    getFiltersList() {
        const filterSelected = this.getFiltersSelected();
        return this.filtersList.map(el => { // Cambia 'filter' por 'map'
            el.filterValue.map(fValue => { // Cambia 'filter' por 'map'

                const exists = filterSelected.find(fSelected => (fSelected.formControl === fValue.filterType && +fSelected.id === fValue.key))
                fValue.isSelected = !!exists;
            })
            return el; // Devuelve el elemento modificado
        });
    }

    getFiltersServicesList() {
        return this.filtersServicesList.map(el => {
            // Safely access 'el.filterType' on 'this.filters' using optional chaining
            // If 'this.filters' is undefined, 'exists' will be 'undefined' instead of throwing an error
            // if (el.filterType !== "permiteMascotas") {
            // const exists = this.filters?.[el.filterType];
            // el.isSelected = !!exists;
            // } else {
            const exists = this.filterService.getFilter()?.[el.filterType];
            el.isSelected = !!exists;
            // }
            return el;
        });
    }



    setFilterParams(filters: Partial<FiltersInterface>) {
        this.selectedFilters = { ...this.selectedFilters, ...filters };
        this.getFiltersSelected()
    }

    @Output() applyFilters = new EventEmitter<any>();
    applyAllFilters() {
        const query = this.filterService.getAnyFilter();
        this._router.navigate([], {
            relativeTo: this._activatedRoute,
            queryParams: query,
            queryParamsHandling: '' // Asegúrate de que los parámetros se fusionen correctamente
        }).then(() => {
            this.filterService.emitFilterSideChange(true);
            this._cdr.detectChanges(); // Asegúrate de que la vista se actualice
        });
    }

    removeAllFilters() {
        // Obtener los filtros seleccionados actualmente
        const selectedFilters = this.selectedFilters;
        // Eliminar solo los filtros que están presentes en la URL
        for (const key in selectedFilters) {
            this._removeFilters(key as FormFilterType);
        }
        this.isSelected = false;
        this.filterService.clearFiltersSide();
        this.applyAllFilters();
        this._cdr.detectChanges();
    }

    private _removeFilters(filter: FormFilterType) {
        let filterService
        this.filterService.deleteAnyFilter(filter);
        for (let i = 0; i < this.filtersServicesList.length; i++) {
            if (this.filtersServicesList[i].filterType === filter) {
                filterService = this.filtersServicesList[i]
            }
        }
        if (filter === 'permiteMascotas') {
            this.petService.petQuantity = 0;
            this.isPetSelected = false;
        }
        const { [filter]: oldFilter, ...rest } = this.selectedFilters;
        delete this.selectedFilters[filter];
        this.setFilterParams({ ...rest });
        this.filterToDeselect = filterService
        this._cdr.detectChanges();
    }

    filterToDeselect: any;


    private _createFilterStructure() {
        this.filtersList = [
            {
                name: 'filtersSideMenu.textBeds',
                filterValue: [
                    {
                        key: 1,
                        value: this.sideMenuTexts['text1Bed'],
                        filterType: FilterSideMenuMeta.controlsKey.camas,
                    },
                    {
                        key: 2,
                        value: this.sideMenuTexts['text2BedsOrMore'],
                        filterType: FilterSideMenuMeta.controlsKey.camas,
                    },
                ]
            },
            {
                name: 'filtersSideMenu.textBedrooms',
                filterValue: [
                    {
                        key: 0,
                        value: 'filtersSideMenu.textStudio',
                        filterType: FilterSideMenuMeta.controlsKey.habitaciones,
                    },
                    {
                        key: 1,
                        value: '1 ' + this.sideMenuTexts['textBedroom'],
                        filterType: FilterSideMenuMeta.controlsKey.habitaciones,
                    },
                    {
                        key: 2,
                        value: '2 ' + this.sideMenuTexts['textBedrooms'],
                        filterType: FilterSideMenuMeta.controlsKey.habitaciones,
                    },
                    {
                        key: 3,
                        value: '3 ' + this.sideMenuTexts['textBedrooms'],
                        filterType: FilterSideMenuMeta.controlsKey.habitaciones,
                    },
                    {
                        key: 4,
                        value: '4 ' + this.sideMenuTexts['textBedrooms'] + " " + this.sideMenuTexts['textMore'],
                        filterType: FilterSideMenuMeta.controlsKey.habitaciones,
                    }
                ]
            },
            {
                name: 'filtersSideMenu.textBathrooms',
                filterValue: [
                    {
                        key: 1,
                        value: '1 ' + this.sideMenuTexts['textBathroom'],
                        filterType: FilterSideMenuMeta.controlsKey.banos,
                    },
                    {
                        key: 2,
                        value: '2 ' + this.sideMenuTexts['textBathrooms'],
                        filterType: FilterSideMenuMeta.controlsKey.banos,
                    },
                    {
                        key: 3,
                        value: '3 ' + this.sideMenuTexts['textBathrooms'],
                        filterType: FilterSideMenuMeta.controlsKey.banos,
                    },
                    {
                        key: 4,
                        value: '4 ' + this.sideMenuTexts['textBathrooms'] + " " + this.sideMenuTexts['textMore'],
                        filterType: FilterSideMenuMeta.controlsKey.banos,
                    },
                    {
                        key: 1,
                        value: this.sideMenuTexts['textSharedBathroom'],
                        filterType: FilterSideMenuMeta.controlsKey.banoCompartido,
                    },
                    {
                        key: 1,
                        value: this.sideMenuTexts['textPrivateBathroom'],
                        filterType: FilterSideMenuMeta.controlsKey.banoPrivado,
                    }
                ]
            },
            {
                name: 'Parking',
                filterValue: [
                    {
                        key: 1,
                        value: 'filtersSideMenu.textInsideParking',
                        filterType: FilterSideMenuMeta.controlsKey.parkingDentro,
                    },
                    {
                        key: 2,
                        value: 'filtersSideMenu.textOutsideParking',
                        filterType: FilterSideMenuMeta.controlsKey.parkingFuera,
                    },
                ]
            },
            this.filtersConfigMeta.otherServices,
            this.filtersConfigMeta.security,
        ];
        this.filtersServicesList = [
            this.filtersConfigMeta.workspace,
            this.filtersConfigMeta.wifi,
            this.filtersConfigMeta.boolParking,
            this.filtersConfigMeta.petFriendly,
        ].map(el => el.filterValue).flat();

        this._createFilterValuesFlat();
    }


    private _createFilterValuesFlat() {
        this.filtersValuesFlat = this.filtersList.map(el => el.filterValue).flat();
    }

    clearFilters() {
        this.removeAllFilters()
    }

    //seccion desabilitada debido a cambios en la definicion de filtros, se mantiene el codigo por si se requiere en un futuro

    // disableCategory() {
    //     const filter = this.typeAccomodationService.getTypeAccomodation();
    //     if (filter === "BedBreakfast") {
    //         if (this.filtersList[1] && this.filtersList[1].filterValue) {
    //             this.filtersList[1].filterValue.forEach((value, index) => {
    //                 if (index <= 4) value.isDisabled = true;
    //             });
    //         }
    //         if (this.filtersList[2] && this.filtersList[2].filterValue) {
    //             this.filtersList[2].filterValue.forEach((value, index) => {
    //                 if (index <= 3) value.isDisabled = true;
    //             });
    //         }
    //         if (this.filtersList[6] && this.filtersList[6].filterValue) {
    //             this.filtersList[6].filterValue[0].isDisabled = true;
    //         }
    //     } else if (filter === "Apartments" || filter === "Houses" || this.isPetSelected) {
    //         if (this.filtersList[0] && this.filtersList[0].filterValue) {
    //             this.filtersList[0].filterValue.forEach((value, index) => {
    //                 if (index <= 3) value.isDisabled = true;
    //             });
    //         }
    //         if (this.filtersList[2] && this.filtersList[2].filterValue) {
    //             this.filtersList[2].filterValue.forEach((value, index) => {
    //                 if (index >= 4 && index <= 5) value.isDisabled = true;
    //             });
    //         }
    //     }
    // }


    private _getFormFieldsControlValues(controlsKey: FormFilterType, id: string | number) {
        return this.filtersValuesFlat.find(res => (res.key === +id && res.filterType === controlsKey))?.value ?? '';
    }

    private _getImmutablesValues(controlsKey: ControlsKeyImmutableType, id: string) {
        let response = '';
        if (controlsKey === this.filtersConfigMeta.controlsKeyImmutables.purpose) {
            response = `searchBar.text${id}`
        } else if (controlsKey === this.filtersConfigMeta.controlsKeyImmutables.type) {
            this.propertyTypes$.pipe(
                take(1)
            )
                .subscribe(
                    res => {
                        response = res.find(el => el.codTipoPropiedad.toString() === id)?.descripcion ?? '';
                    }
                )
        }
        return response;
    }

    private _getPropertyTypes() {
        this._store.dispatch(PropertyTypesPageActions.init());
        this.propertyTypes$ = this._store.select(PropertyTypesSelectors.propertyTypes);
    }

    private _initialize() {
        this._activatedRoute.queryParams
            .pipe(
                takeUntil(this._disposed$),
                tap(filter => {
                    this.filters = filter;
                })
            ).subscribe();
    }



    ngOnDestroy(): void {
        this.subscriptions.forEach(subs => subs.unsubscribe())
        this.destroy$.next();
        this.destroy$.complete();
    }

}
